import React, { useContext } from 'react'
import { CBLabel, Flex, Label } from 'styles/PageLayout'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { MaintenanceWindow } from '../domain/DTO'

const timezonesPrCountry: { [key: string]: string[] | undefined } = {
  DE: ['Europe/Berlin'],
  NO: ['Europe/Oslo'],
  SE: ['Europe/Stockholm'],
  GB: ['Europe/London'],
  FR: ['Europe/Paris'],
  AU: [
    'Australia/Perth',
    'Australia/Darwin',
    'Australia/Brisbane',
    'Australia/Adelaide',
    'Australia/Sydney',
    'Europe/Berlin',
  ],
}

const weekdayLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const defaultSelectedWeekdays = 127 // 7 bits up, all days selected

type Props = {
  onChange: (value: MaintenanceWindow) => void
  value: MaintenanceWindow
}

export const MaintenanceWindowSelector = ({ onChange, value }: Props) => {
  const { selectedCountry } = useContext(SelectedCountryContext)

  const flipWeekday = (selectedWeekdays: number, dayIndex: number): number => {
    return selectedWeekdays ^ (2 ** dayIndex) //flip day-th bit in bitmap
  }

  const isWeekdaySelected = (selectedWeekdays: number, dayIndex: number) => {
    return !!(selectedWeekdays & (2 ** dayIndex)) //check day-th bit in bitmap
  }

  return (
    <React.Fragment>
      <Label>
        <span>From: </span>
        <input
          type="time"
          className="select"
          onChange={(e) => onChange({ ...value, start: e.target.value })}
          value={value.start}
          step="900"
        />
      </Label>
      <Label>
        <span>To: </span>
        <input
          type="time"
          className="select"
          onChange={(e) => onChange({ ...value, end: e.target.value })}
          value={value.end}
          step="900"
        />
      </Label>
      <Label>
        <span>Timezone: </span>
        <select className="select" onChange={(e) => onChange({ ...value, tz: e.target.value })} value={value.tz}>
          {(timezonesPrCountry[selectedCountry] ?? ['Europe/Paris']).map((tz) => {
            return (
              <option key={tz} value={tz}>
                {tz}
              </option>
            )
          })}
        </select>
      </Label>
      <Label>
        <span>Weekdays:</span>
        <Flex>
          {weekdayLabels.map((label, index) => (
            <CBLabel key={index}>
              <input
                type="checkbox"
                className="checkbox"
                checked={isWeekdaySelected(value.weekdays ?? defaultSelectedWeekdays, index)}
                onChange={() =>
                  onChange({ ...value, weekdays: flipWeekday(value.weekdays ?? defaultSelectedWeekdays, index) })
                }
              />
              <span>{label}</span>
            </CBLabel>
          ))}
        </Flex>
      </Label>
    </React.Fragment>
  )
}
