import { useState, useEffect, useCallback } from 'react'
import { authenticatedGet } from './authenticatedGet'
import { failure, loading, notAsked, RD, SRD, success } from 'srd'

export type HttpError = {
  status: number
  statusText: string
  body: {
    type: string
    title: string
    status: number
    detail: string
    errorId: string
  }
}

// useCallback() is required to do on the incoming transform function (if it is defined as a lambda in the render function)
// before passing into useRemoteData. This is to avoid retriggering the effect on every render
// (Which will happen if the function is defined in the render)
export function useRemoteData<T>(
  resourceUrl: string,
  transform?: (original: { data: unknown }) => T
): { data: RD<HttpError, T>; refresh: () => void } {
  const [requestAge, setRequestAge] = useState<Date>(new Date())
  const [data, setData] = useState<RD<HttpError, T>>(notAsked())

  useEffect(() => {
    let mounted = true

    if (SRD.isNotAsked(data)) {
      setData(loading())
    }
    authenticatedGet(resourceUrl)
      .then((value: { data: unknown }) => {
        if (mounted) setData(transform ? success(transform(value)) : success(value.data as T))
      })
      .catch((err) => {
        if (mounted) setData(failure(err))
      })

    return () => {
      mounted = false
    }
  }, [resourceUrl, requestAge, transform]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    refresh: () => setRequestAge(new Date()),
  }
}

export function useAutoRefresh(refreshFn: () => void, intervalMillis: number) {
  const refresh = useCallback(() => {
    refreshFn()
  }, [refreshFn])
  useEffect(() => {
    let previousRefresh = new Date()
    const id = setInterval(() => {
      if (!document.hidden) {
        if (new Date().getTime() - previousRefresh.getTime() > intervalMillis) {
          refresh()
          previousRefresh = new Date()
        }
      }
    }, 1000)

    return () => {
      clearInterval(id)
    }
  }, [intervalMillis, refresh])
}
