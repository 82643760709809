import { API_HOST } from 'lib'
import { authenticatedFetch, extractErrorMessage } from 'lib/authenticatedGet'

export const createFileStream = async (streamName: string, type: string, country: string) => {
  return authenticatedFetch(`${API_HOST}/v1/filestream`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        streamName,
        type,
        country,
      },
    }),
  }).catch(extractErrorMessage)
}

export const deleteFileStream = async (streamId: string) => {
  return authenticatedFetch(`${API_HOST}/v1/filestream/${streamId}`, {
    method: 'DELETE',
  }).catch(extractErrorMessage)
}
