import React from 'react'
import { colors } from '../styles'

type LogoProps = {
  color?: string
  size?: string
}

export const Logo = (props: LogoProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={props.size || '36'} viewBox="0 0 33 33" width={props.size || '36'}>
    <path
      d="M22.14,14.86l6.77,0,1,1.81V30.9l-.23.15c-.17.12-.38.26-.57.42l-7.08,0a6.21,6.21,0,0,0-.77-.58l-.17-.12V16.69l1.06-1.83m-1.08-1.5v.37l-1.48,2.55v15c0,.52,1.67,1.14,1.67,1.67l8.59,0c0-.52,1.56-.94,1.56-1.7v-15L30,13.76v-.38l-9,0Z"
      fill={props.color || colors.YELLOW}
    />
    <path
      d="M21.07,19.28l8.81,3.2V28.3l-8.81-3.2V19.28m-1.22-2a.29.29,0,0,0-.28.3v8.38a.32.32,0,0,0,.19.29L31,30.3a.15.15,0,0,0,.09,0,.29.29,0,0,0,.28-.3V21.64a.32.32,0,0,0-.19-.29L20,17.28a.2.2,0,0,0-.1,0Z"
      fill={props.color || colors.YELLOW}
    />
    <rect
      x="24.83"
      y="11.73"
      width="1.5"
      height="2.86"
      transform="translate(-2.26 20.4) rotate(-41.8)"
      fill={props.color || colors.YELLOW}
    />
    <path
      d="M10.63,4.8a44.13,44.13,0,0,1,3.12,4.94,23,23,0,0,1,1.71,4.45v.73A1.65,1.65,0,0,0,15,16a1.68,1.68,0,0,0,.4,1.06,1.62,1.62,0,0,0-.37,1.07,1.65,1.65,0,0,0,.4,1.06v8.51a2.41,2.41,0,0,0-.27,1.1,2.44,2.44,0,0,0,.27,1v.23c0,.24,0,.57,0,1.14v.1a4,4,0,0,1-1.36.17H4.87a4.07,4.07,0,0,1-1.48-.17V30.14a2,2,0,0,0,.42-1.24,2.08,2.08,0,0,0-.42-1.22V19.25a1.65,1.65,0,0,0,.32-1,1.62,1.62,0,0,0-.32-.95v-.17a1.61,1.61,0,0,0,.3-1,1.69,1.69,0,0,0-.29-.95c0-.21,0-.5,0-.9A19.94,19.94,0,0,1,4.83,9.8a41.43,41.43,0,0,1,3-5h2.78M11.7,3.3h-5c0,.2,0,.45,0,.45A49,49,0,0,0,3.45,9.2a19.48,19.48,0,0,0-1.56,5.09c0,.78,0,1.12,0,1.33s.3.29.3.51-.3.17-.3.5,0,1,0,1.15.32.23.32.47-.32.17-.32.47,0,8.87,0,9.25.42.59.42.94-.42.53-.42.88,0,.62,0,1.54c0,1.3,1.13,1.67,3,1.67h9.24C16,33,17,32.54,17,31.21c0-.92,0-1.18,0-1.54s-.26-.52-.27-.87.27-.56.27-.95V18.6c0-.3-.4-.2-.4-.47s.4-.16.4-.46V16.51c0-.33-.43-.27-.43-.49s.43-.2.43-.51,0-.55,0-1.34a21,21,0,0,0-1.85-5.06,48.8,48.8,0,0,0-3.41-5.4V3.3Z"
      fill={props.color || colors.YELLOW}
    />
    <path
      d="M11.12,1V2.32H7.26V1h3.86m.7-1H6.56a.31.31,0,0,0-.3.33V3a.31.31,0,0,0,.3.33h5.26a.31.31,0,0,0,.3-.33V.33a.31.31,0,0,0-.3-.33Z"
      fill={props.color || colors.YELLOW}
    />
    <path
      d="M15.46,18.88v7.56h-12V18.88h12m1.2-1.5H2.22a.31.31,0,0,0-.3.3v10a.3.3,0,0,0,.3.3H16.66a.3.3,0,0,0,.3-.3v-10a.31.31,0,0,0-.3-.3Z"
      fill={props.color || colors.YELLOW}
    />
    <path
      d="M6.2,20.26H7l1.8,4.93H7.94L7.53,24H5.62l-.4,1.16H4.4Zm1.08,3-.7-2-.7,2Z"
      fill={props.color || colors.YELLOW}
    />
    <path
      d="M9.4,21.48h.79V22a1.16,1.16,0,0,1,1-.61,1,1,0,0,1,1,.6,1.31,1.31,0,0,1,1.09-.6c.68,0,1.13.46,1.13,1.3v2.49h-.79V22.93c0-.53-.22-.81-.63-.81s-.66.29-.66.82v2.25h-.8V22.92c0-.52-.23-.8-.62-.8s-.67.31-.67.83v2.24H9.4Z"
      fill={props.color || colors.YELLOW}
    />
  </svg>
)

export default Logo
