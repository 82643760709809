import React from 'react'
import { FileSummary } from 'domain/DTO'
import { PagedTable } from '@tomra/react-table'
import { Link } from 'react-router-dom'

export const FileHistory = ({ history }: { history: FileSummary[] }) => {
  return (
    <PagedTable
      className="table"
      data={history}
      columnHeaders={['File Version', 'File Name', 'Uploaded']}
      rowFactory={(history) => {
        return (
          <tr key={history.created + history.fileName}>
            <td>{history.parsedVersion}</td>
            <td>
              {history.url ? (
                <Link className="link" to={history.url}>
                  {history.fileName}
                </Link>
              ) : (
                history.fileName
              )}
            </td>
            <td>
              {new Date(history.created).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </td>
          </tr>
        )
      }}
      initialRecordsPerPage={100}
      recordsPerPageOptions={[10, 20, 50, 100]}
    />
  )
}
