import React from 'react'

type CopyIconProps = {
  size?: string
}

export const CopyIcon = (props: CopyIconProps) => (
  <svg
    fill="none"
    height={props.size || '24'}
    width={props.size || '24'}
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect className="a" x="0.75" y="0.75" width="18" height="18" rx="1.5" ry="1.5" />
    <path className="a" d="M21.75,5.25a1.5,1.5,0,0,1,1.5,1.5v15a1.5,1.5,0,0,1-1.5,1.5h-15a1.5,1.5,0,0,1-1.5-1.5" />
  </svg>
)
