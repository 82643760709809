import { ScopeFilter, ScopeFilters } from '../domain/DTO'

export type StructureNode = {
  type: string
  display: string
  deepChildCount: number
  id: string
  children: StructureNode[]
  status: NodeStatus
  conflictCommons: string[]
  filterableProperties?: { [type: string]: string | undefined }
}

export enum NodeStatus {
  INCLUDED = 'INCLUDED',
  EXCLUDED = 'EXCLUDED',
  PARTIAL = 'PARTIAL',
  NONE = 'NONE',
}

export enum FilterType {
  CLEARING_HOUSE = 'CLEARING_HOUSE',
}

export type ScopeFilterWithStatus = {
  type: FilterType
  value: string
  status: NodeStatus.NONE | NodeStatus.INCLUDED | NodeStatus.EXCLUDED
}

export class ScopeFilterConfiguration {
  scopeFilters: ScopeFilterWithStatus[]
  constructor(scopeFilters: ScopeFilterWithStatus[]) {
    this.scopeFilters = scopeFilters
  }
  public isScopeFiltersEnabled = (): boolean => {
    return this.scopeFilters.some((f) => f.status !== NodeStatus.NONE)
  }
}

export type PathScope = {
  include: string[]
  exclude: string[]
  unknownIncludes?: string[]
  unknownExcludes?: string[]
}

export type Entity = {
  id: string
  type: string
}

export type Scope = {
  include: Entity[]
  exclude: Entity[]
}

export type InstallationScopes = {
  scopes: Scope[]
}

export const toInstallationScope = (pathScopes: PathScope[]): InstallationScopes => {
  const scopes: Scope[] = pathScopes.map((pathScope) => {
    return {
      include: pathScope.include.map((raw) => {
        const parts = raw.split(';')
        const finalPart = parts[parts.length - 1].split(':')
        return { type: finalPart[0], id: finalPart[1] }
      }),
      exclude: pathScope.exclude.map((raw) => {
        const parts = raw.split(';')
        const finalPart = parts[parts.length - 1].split(':')
        return { type: finalPart[0], id: finalPart[1] }
      }),
    }
  })
  return { scopes }
}

export const fromInstallationScope = ({ scopes }: InstallationScopes, structureNodes: StructureNode[]): PathScope[] => {
  return scopes.map((scope) => {
    const include = scope.include.map((entity) => getPathForEntityInTreeStructure(entity, structureNodes))
    const exclude = scope.exclude.map((entity) => getPathForEntityInTreeStructure(entity, structureNodes))
    return {
      include: include.filter((path) => 'hit' in path).map((path) => (path as { hit: string }).hit),
      exclude: exclude.filter((path) => 'hit' in path).map((path) => (path as { hit: string }).hit),
      unknownIncludes: include.filter((path) => 'miss' in path).map((path) => (path as { miss: string }).miss),
      unknownExcludes: exclude.filter((path) => 'miss' in path).map((path) => (path as { miss: string }).miss),
    }
  })
}

const getPathForEntityInTreeStructure = (
  entity: Entity,
  structureNodes: StructureNode[]
): { hit: string } | { miss: string } => {
  const target = `${entity.type}:${entity.id}`
  const foundNode = findInNodes(target, structureNodes, '')
  return foundNode ? { hit: foundNode } : { miss: target }
}

const findInNodes = (target: string, nodes: StructureNode[], pathPrefix: string): string | null => {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    const nodeId = `${node.type}:${node.id}`
    if (target === nodeId) {
      return pathPrefix + target
    }
    const foundChild = findInNodes(target, node.children, pathPrefix + nodeId + ';')
    if (foundChild) {
      return foundChild
    }
  }
  return null
}

export const searchInNodes = (
  searchTerm: string,
  nodes: StructureNode[],
  pathPrefix: string,
  foundChildren: string[]
): string[] => {
  const pattern = RegExp(searchTerm, 'gi')

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    const nodeId = `${node.type}:${node.id}`

    if (node.display.match(pattern)) {
      foundChildren.push(pathPrefix + nodeId)
    }
    searchInNodes(searchTerm, node.children, pathPrefix + nodeId + ';', foundChildren)
  }
  return foundChildren
}

export function filterAppliedScopeFilters(scopeFilters: ScopeFilterWithStatus[]): ScopeFilterWithStatus[] {
  return scopeFilters.filter((filter) => filter.status !== NodeStatus.NONE)
}

export const toScopeFiltersWithStatus = (scopeFilters: ScopeFilters | undefined): ScopeFilterWithStatus[] => {
  return (
    scopeFilters?.scopeFilters?.map((scopeFilter) => {
      return {
        type: scopeFilter.type,
        value: scopeFilter.value,
        status: scopeFilter.include ? NodeStatus.INCLUDED : NodeStatus.EXCLUDED,
      }
    }) ?? []
  )
}

export const toDefaultScopeFiltersWithStatus = (scopeFilters: ScopeFilters | undefined): ScopeFilterWithStatus[] => {
  return (
    scopeFilters?.scopeFilters?.map((scopeFilter) => {
      return { type: scopeFilter.type, value: scopeFilter.value, status: NodeStatus.NONE }
    }) ?? []
  )
}

export const toScopeFilters = (scopeFiltersWithStatus: ScopeFilterWithStatus[]): ScopeFilters => {
  const scopeFilters: ScopeFilter[] = scopeFiltersWithStatus.map((filter) => {
    return {
      type: filter.type,
      value: filter.value,
      include: filter.status === NodeStatus.INCLUDED,
    }
  })
  return { scopeFilters }
}
