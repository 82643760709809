export function parseLocationSearchAsQueryParameters(search: string): [string, string][] {
  const queryParameters = search.substr(1).split('&')
  return queryParameters
    .map((queryParameter) => queryParameter.split('=') as [string, string])
    .filter((parsed) => parsed.length === 2)
}

export function toQueryParamUrlString(queryParameters: [string, string][]): string {
  return '?' + queryParameters.map((keyValue) => keyValue.join('=')).join('&')
}

export function updateQueryParamValue(
  queryParameters: [string, string][],
  keyToReplace: string,
  newValue: string
): [string, string][] {
  if (newValue === '') {
    return queryParameters.filter(([key, _]) => key !== keyToReplace)
  }

  const exists = queryParameters.some(([key, _]) => key === keyToReplace)
  if (exists) {
    return queryParameters.map(([key, value]) => {
      const chosenValue = key === keyToReplace ? newValue : value
      return [key, chosenValue]
    })
  } else {
    return [...queryParameters, [keyToReplace, newValue]]
  }
}
