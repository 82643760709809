import React, { useState } from 'react'
import { FormLayout } from 'styles/FormLayout'
import {
  ReturnActionType,
  DisabledActionType,
  RefundGroupActionType,
  SuperGroupActionType,
  ShapeAction,
  Config,
} from 'domain/Assortment'
import { SelectAction, SelectActionWithValue } from '../CommonComponents'

export type ShapeActionSnapshot = {
  returnAction?: ShapeAction
  disabledAction?: ShapeAction
  refundGroupAction?: ShapeAction
  superGroupAction?: ShapeAction
}

export type DisabledShapeActions = {
  returnAction: boolean
  disabledAction: boolean
  refundGroupAction: boolean
  superGroupAction: boolean
}

type Props = {
  config: Config
  id: string
  snapshot: ShapeActionSnapshot
  disabled: DisabledShapeActions
  onApply: (id: string, snapshot: ShapeActionSnapshot) => void
}

export const ShapeActionDialog = ({ config, id, snapshot, disabled, onApply }: Props) => {
  const [returnAction, setReturnAction] = useState<ShapeAction | undefined>(snapshot.returnAction)
  const [disabledAction, setDisabledAction] = useState<ShapeAction | undefined>(snapshot.disabledAction)
  const [refundGroupAction, setRefundGroupAction] = useState<ShapeAction | undefined>(snapshot.refundGroupAction)
  const [superGroupAction, setSuperGroupAction] = useState<ShapeAction | undefined>(snapshot.superGroupAction)
  return (
    <div className="card">
      <FormLayout.Content>
        <h3>Active actions</h3>
        <SelectAction
          config={config}
          label={'RETURN action:'}
          type={returnAction?.type}
          disabled={disabled.returnAction}
          allowOverride={returnAction?.allowOverride}
          options={[ReturnActionType.SET, ReturnActionType.CLEAR]}
          onTypeChanged={(value) => setReturnAction({ ...returnAction, type: value as ReturnActionType })}
          onAllowOverrideChanged={(allow) => {
            if (returnAction) {
              setReturnAction({ ...returnAction, allowOverride: allow })
            }
          }}
        />
        <SelectAction
          config={config}
          label={'DISABLED action:'}
          type={disabledAction?.type}
          disabled={disabled.disabledAction}
          allowOverride={disabledAction?.allowOverride}
          options={[DisabledActionType.SET, DisabledActionType.CLEAR]}
          onTypeChanged={(value) => setDisabledAction({ ...disabledAction, type: value as DisabledActionType })}
          onAllowOverrideChanged={(allow) => {
            if (disabledAction) {
              setDisabledAction({ ...disabledAction, allowOverride: allow })
            }
          }}
        />
        <SelectActionWithValue
          config={config}
          label={'REFUND GROUP action:'}
          type={refundGroupAction?.type}
          disabled={disabled.refundGroupAction}
          value={refundGroupAction?.value}
          allowOverride={refundGroupAction?.allowOverride}
          options={[RefundGroupActionType.SET]}
          onTypeChanged={(value) =>
            setRefundGroupAction({ ...refundGroupAction, type: value as RefundGroupActionType })
          }
          onAllowOverrideChanged={(allow) => {
            if (refundGroupAction) {
              setRefundGroupAction({ ...refundGroupAction, allowOverride: allow })
            }
          }}
          onValueChanged={(value: string) => {
            if (refundGroupAction) {
              setRefundGroupAction({ ...refundGroupAction, value: value })
            }
          }}
          maxValue={39}
        />
        <SelectActionWithValue
          config={config}
          label={'SUPER GROUP action:'}
          type={superGroupAction?.type}
          disabled={disabled.superGroupAction}
          value={superGroupAction?.value}
          allowOverride={superGroupAction?.allowOverride}
          options={[SuperGroupActionType.SET]}
          onTypeChanged={(value) => setSuperGroupAction({ ...superGroupAction, type: value as SuperGroupActionType })}
          onAllowOverrideChanged={(allow) => {
            if (superGroupAction) {
              setSuperGroupAction({ ...superGroupAction, allowOverride: allow })
            }
          }}
          onValueChanged={(value: string) =>
            setSuperGroupAction({
              type: superGroupAction?.type ?? SuperGroupActionType.SET,
              value: value,
            })
          }
          maxValue={59}
        />
        <FormLayout.FooterContainer>
          <FormLayout.Footer>
            <button
              className="btn btn-primary-dark py-sm"
              onClick={() => {
                onApply(id, {
                  returnAction: returnAction,
                  disabledAction: disabledAction,
                  refundGroupAction: refundGroupAction,
                  superGroupAction: superGroupAction,
                })
              }}
            >
              <span>Save</span>
            </button>
          </FormLayout.Footer>
        </FormLayout.FooterContainer>
      </FormLayout.Content>
    </div>
  )
}
