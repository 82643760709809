import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CommonsAndInstallationsContext } from 'domain/CommonsAndInstallationsContext'
import { InstallationAssortment, CommonReference } from 'domain/DTO'
import { SRD } from 'srd'
import { SearchIcon } from '../icons'

function getInstallationCount(installations: InstallationAssortment[], commonId: string): number | undefined {
  return installations.map((installation) => installation.assortmentStatus?.commonId).filter((id) => id === commonId)
    .length
}

export const CommonReferences = ({ commons }: { commons: Array<CommonReference> }) => {
  const { data: contextRemoteData } = useContext(CommonsAndInstallationsContext)

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Installations</th>
        </tr>
      </thead>
      <tbody>
        {commons.map((common) => {
          const link = `/common-assortment/${common.commonId}`
          return (
            <tr key={common.commonId}>
              <td>
                <Link to={link}>{`📑 ${common.name}`}</Link>
              </td>
              <td>
                <Link to={'/installations?commonId=' + common.commonId}>
                  {SRD.isSuccess(contextRemoteData) ? (
                    <div className="inline-flex">
                      <span className="link mr-sm">
                        {getInstallationCount(contextRemoteData.data.installations, common.commonId)}
                      </span>
                      <SearchIcon color="none" size="18" />
                    </div>
                  ) : (
                    <div className="loadingSpinner text-sm" />
                  )}
                </Link>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
