import React, { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useRemoteData } from 'lib/RemoteData'
import { API_HOST } from 'lib'
import { Dialog } from '../../components/Dialog'
import { authenticatedFetch, authenticatedPost, extractErrorMessage } from 'lib/authenticatedGet'
import { Container, InnerContainer, Label } from 'styles/PageLayout'
import { Link } from 'react-router-dom'
import { FileUploadResultDialog } from './FileUploadResultDialog'
import { FileStreamDialog } from 'components/FileStreamDialog'
import { TitleWithEdit } from 'components/TitleWithEdit'
import { CommonsAndInstallationsContext } from 'domain/CommonsAndInstallationsContext'
import { FormLayout } from 'styles/FormLayout'
import styled from 'styled-components'
import { FileHistory } from 'components/FileHistory'
import { CommonReferences } from 'components/CommonReferences'
import { FileStreamWithHistory, FileUploadResult } from 'domain/DTO'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { SRD } from 'srd'
import { ArrowRightIcon } from '../../icons'
import HomeIcon from '../../icons/HomeIcon'

const FileHeader = styled.div`
  display: flex;
  flex-direction: column;
`

const FileHeaderRow = styled.div`
  display: flex;
`

const FileHeaderLabel = styled.div`
  flex: 1 0 5rem;
  min-height: 1.5rem;
  font-weight: 'bold';
`

const FileHeaderValue = styled.div`
  flex: 1 0 15rem;
  font-weight: 'bold';
`

const postFileAsMultipart = async (file: File, streamId: string) => {
  const form = new FormData()
  form.append('file', file)
  return authenticatedPost(
    `${API_HOST}/v1/filestream/stage/${streamId}`,
    {
      body: form,
    },
    600000
  )
}

type Tabs = 'HISTORY' | 'COMMONS'

function LoadingSpinner() {
  return (
    <div className="card">
      <div data-testid="loading">
        <div className="loadingSpinner" />
      </div>
    </div>
  )
}

export const FileStream = () => {
  const { refresh: refreshStatuses } = useContext(CommonsAndInstallationsContext)
  const { id } = useParams<{ id: string }>()
  if (!id) {
    throw new Error('Missing id parameter')
  }
  const { data: fileStreamRemoteData, refresh: refreshFileStream } = useRemoteData<FileStreamWithHistory>(
    `${API_HOST}/v1/filestream/${id}`
  )
  const fileInput = useRef<HTMLInputElement>(null)
  const [fileUploadResult, setFileUploadResult] = useState<FileUploadResult | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [activeTab, setActiveTab] = useState<Tabs>('COMMONS')
  const { selectedCountry } = useContext(SelectedCountryContext)

  return SRD.match(
    {
      notAsked: () => <LoadingSpinner />,
      loading: () => <LoadingSpinner />,
      failure: (err) => (
        <div className="card">
          <div className="centerAbsolute">
            <div className="alert alert-danger">
              Uh-oh.. Failed to load File Stream Details
              <div>{err.body?.title}</div>
            </div>
          </div>
        </div>
      ),
      success: (value) => (
        <div>
          <div className="py-md px-lg flex items-center space-x-md">
            <Link aria-label="Home" to={'/'}>
              <HomeIcon size="1.5rem" />
            </Link>
            <ArrowRightIcon size="1rem" />
            <Link to="/file-stream">
              <h2>Files</h2>
            </Link>
            <ArrowRightIcon size="1rem" />
            <h2>{value.name}</h2>
          </div>

          <div className="card">
            <Container>
              <TitleWithEdit title={value.name} onClick={() => setShowEditDialog(true)} />
              <FileHeader>
                <FileHeaderRow>
                  <FileHeaderLabel>Type: </FileHeaderLabel>
                  <FileHeaderValue>{value.fileType}</FileHeaderValue>
                </FileHeaderRow>
                <FileHeaderRow>
                  <FileHeaderLabel>Name: </FileHeaderLabel>
                  <FileHeaderValue>
                    {value.current ? (
                      value.current.url ? (
                        <Link className="link" to={value.current.url}>
                          {value.current.fileName}
                        </Link>
                      ) : (
                        value.current.fileName
                      )
                    ) : (
                      'No file uploaded yet..'
                    )}
                  </FileHeaderValue>
                </FileHeaderRow>
                <FileHeaderRow>
                  <FileHeaderLabel>Version: </FileHeaderLabel>
                  <FileHeaderValue>{value.current ? value.current.parsedVersion : ''}</FileHeaderValue>
                </FileHeaderRow>
                <FileHeaderRow>
                  <FileHeaderLabel>Uploaded: </FileHeaderLabel>
                  <FileHeaderValue>{value.current?.created?.toLocaleString() ?? ''}</FileHeaderValue>
                </FileHeaderRow>
              </FileHeader>
              <InnerContainer
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 50 }}
              >
                <Label>
                  <br />
                  <input type="file" ref={fileInput} />
                </Label>
                <button
                  className="btn btn-primary-dark py-sm"
                  disabled={isUploading}
                  style={{ marginTop: 20 }}
                  onClick={async (e) => {
                    e.preventDefault()
                    setIsUploading(true)
                    if (fileInput.current && fileInput.current.files) {
                      try {
                        const responseBody = await postFileAsMultipart(fileInput.current.files[0], id)
                        setFileUploadResult(responseBody.data as FileUploadResult)
                      } catch (err: any) {
                        setFileUploadResult({
                          stagedFile: null,
                          diff: null,
                          messages: [{ entityId: null, severity: 'ERROR', message: err?.toString() }],
                        })
                      }
                      setIsUploading(false)
                    }
                  }}
                >
                  <FormLayout.LoadingButtonText
                    loading={isUploading}
                    text="Upload File"
                    loadingText="Uploading File..."
                  />
                </button>
              </InnerContainer>
              <div>
                <div className="tabs w-6/12">
                  <button
                    className={`tab ${activeTab === 'COMMONS' ? 'active' : ''}`}
                    onClick={() => {
                      setActiveTab('COMMONS')
                    }}
                  >
                    Used by
                  </button>
                  <button
                    className={`tab ${activeTab === 'HISTORY' ? 'active' : ''}`}
                    onClick={() => {
                      setActiveTab('HISTORY')
                    }}
                  >
                    File history
                  </button>
                </div>
                {activeTab === 'COMMONS' && <CommonReferences commons={value.commonAssortmentReferences} />}
                {activeTab === 'HISTORY' && <FileHistory history={value.history} />}
              </div>
              {fileUploadResult ? (
                <Dialog onClose={() => setFileUploadResult(null)} shouldCloseOnOverlayClick={false}>
                  <div className="card">
                    <FileUploadResultDialog
                      result={fileUploadResult}
                      latestFileVersion={value.current ? value.current.parsedVersion : null}
                      onApply={() => {
                        return authenticatedFetch(`${API_HOST}/v1/filestream/apply/${id}`, {
                          method: 'POST',
                          body: JSON.stringify({ data: fileUploadResult.stagedFile }),
                        }).then(() => {
                          setFileUploadResult(null)
                          refreshFileStream()
                          refreshStatuses()
                        })
                      }}
                    />
                  </div>
                </Dialog>
              ) : null}
              {showEditDialog ? (
                <Dialog shouldCloseOnOverlayClick={false} onClose={() => setShowEditDialog(false)}>
                  <FileStreamDialog
                    editing={{ name: value.name, type: value.fileType }}
                    onComplete={async (name, type) => {
                      updateFileStream(value.streamId, name, selectedCountry, type).finally(() => {
                        setShowEditDialog(false)
                        refreshFileStream()
                      })
                    }}
                  />
                </Dialog>
              ) : null}
            </Container>
          </div>
        </div>
      ),
    },
    fileStreamRemoteData
  )
}

const updateFileStream = async (id: string, streamName: string, country: string, type: string) => {
  return authenticatedFetch(`${API_HOST}/v1/filestream/${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      data: {
        streamName,
        country,
        type,
      },
    }),
  }).catch(extractErrorMessage)
}
