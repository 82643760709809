import { authenticatedFetch } from 'lib/authenticatedGet'

type Installation = 'Installation'
type Common = 'Common'
export type Config = Installation | Common
export function isCommon(config: Config) {
  return config === 'Common'
}
export function isInstallation(config: Config) {
  return config === 'Installation'
}

export type BarcodeValue = {
  code: string
  comment: string
  open: boolean
  accepted: boolean
}

export const UNSET = 'UNSET'

export const ActionNames: { [key: string]: string } = {
  SET_CLOSED: 'Set closed',
  CLEAR_CLOSED: 'Clear closed',
  SET_REJECT: 'Set reject',
  SET_RETURN: 'Set return',
  CLEAR_RETURN: 'Clear return',
  SET_DISABLED: 'Set disabled',
  CLEAR_DISABLED: 'Clear disabled',
  SET_SUPER_GROUP: 'Set super group',
  SET_REFUND_GROUP: 'Set refund group',
  SET_ACCEPT: 'Set accept',
  CLEAR_ACCEPT: 'Clear accept',
  SET_IN_ASSORTMENT: 'Set in assortment',
  CLEAR_IN_ASSORTMENT: 'Clear in assortment',
  SET_TRANSPORT_END_SONDERPFAND: 'Set transport end for sonderpfand group',
  SET_REJECT_SONDERPFAND: 'Set reject for sonderpfand group',
  DISABLE_ALL: 'Disable all',
}

export enum ClosedActionType {
  SET = 'SET_CLOSED',
  CLEAR = 'CLEAR_CLOSED',
}

export enum RejectActionType {
  SET = 'SET_REJECT',
}

export type BarcodeActionType = ClosedActionType | RejectActionType | 'UNSET'

export type BarcodeAction = {
  type: BarcodeActionType
  allowOverride?: boolean
  value?: string
}

export enum SonderpfandTransportEndActionType {
  SET = 'SET_TRANSPORT_END_SONDERPFAND',
}

export enum SonderpfandRejectActionType {
  SET = 'SET_REJECT_SONDERPFAND',
}

export type SonderpfandActionType = SonderpfandTransportEndActionType | SonderpfandRejectActionType | 'UNSET'

export type SonderpfandAction = {
  type: SonderpfandActionType
  allowOverride?: boolean
  value?: string
}

export enum ReturnActionType {
  SET = 'SET_RETURN',
  CLEAR = 'CLEAR_RETURN',
}

export enum DisabledActionType {
  SET = 'SET_DISABLED',
  CLEAR = 'CLEAR_DISABLED',
}

export enum SuperGroupActionType {
  SET = 'SET_SUPER_GROUP',
}

export enum DisableAllActionType {
  SET = 'DISABLE_ALL',
}

export enum RefundGroupActionType {
  SET = 'SET_REFUND_GROUP',
}
export type ShapeActionType =
  | ReturnActionType
  | DisabledActionType
  | DisableAllActionType
  | RefundGroupActionType
  | SuperGroupActionType
  | 'UNSET'

export type ShapeAction = {
  type: ShapeActionType
  allowOverride?: boolean
  value?: string
}

export enum AcceptActionType {
  SET = 'SET_ACCEPT',
  CLEAR = 'CLEAR_ACCEPT',
}

export enum InAssortmentActionType {
  SET = 'SET_IN_ASSORTMENT',
  CLEAR = 'CLEAR_IN_ASSORTMENT',
}

export type CrateActionType = AcceptActionType | InAssortmentActionType | 'UNSET'

export type CrateAction = {
  type: CrateActionType
  allowOverride?: boolean
  value?: string
}

export const isCloseAction = (action: string | undefined): action is ClosedActionType => {
  return action === ClosedActionType.SET || action === ClosedActionType.CLEAR
}

export const isRejectAction = (action: string | undefined): action is RejectActionType => {
  return action === RejectActionType.SET
}

export const isReturnAction = (action: string | undefined): action is ReturnActionType => {
  return action === ReturnActionType.SET || action === ReturnActionType.CLEAR
}

export const isDisabledAction = (action: string | undefined): action is DisabledActionType => {
  return action === DisabledActionType.SET || action === DisabledActionType.CLEAR
}

export const isDisableAllAction = (action: string | undefined): action is DisableAllActionType => {
  return action === DisableAllActionType.SET
}

export const isRefundGroupAction = (action: string | undefined): action is RefundGroupActionType => {
  return action === RefundGroupActionType.SET
}

export const isSuperGroupAction = (action: string | undefined): action is SuperGroupActionType => {
  return action === SuperGroupActionType.SET
}

export const isAcceptActionType = (action: string | undefined): action is AcceptActionType => {
  return action === AcceptActionType.SET || action === AcceptActionType.CLEAR
}

export const isInAssortmentActionType = (action: string | undefined): action is InAssortmentActionType => {
  return action === InAssortmentActionType.SET || action === InAssortmentActionType.CLEAR
}

export const isSonderpfandAction = (action: string | undefined): action is SonderpfandActionType => {
  return action === SonderpfandRejectActionType.SET || action === SonderpfandTransportEndActionType.SET
}

export const stagedIfChanged = <T extends CrateAction | ShapeAction | BarcodeAction | SonderpfandAction>(
  staged: T | undefined,
  original: T | undefined
): T | undefined => {
  const stagedType = staged?.type ?? 'UNSET'
  const originalType = original?.type ?? 'UNSET'

  if (
    stagedType !== originalType ||
    staged?.value !== original?.value ||
    staged?.allowOverride !== original?.allowOverride
  ) {
    return staged
  }
  return undefined
}

export const originalHasChanged = (
  staged: ShapeAction | CrateAction | BarcodeAction | SonderpfandAction | undefined,
  original: ShapeAction | CrateAction | BarcodeAction | SonderpfandAction | undefined
): boolean => {
  return !!(
    staged &&
    (staged?.type !== original?.type ||
      staged?.value !== original?.value ||
      staged?.allowOverride !== original?.allowOverride)
  )
}

export const withoutUndefinedActions = <T extends { [key: string]: any }>(obj: T): T => {
  const newObj = { ...obj }
  Object.keys(newObj).forEach((key) => (newObj[key] === undefined ? delete newObj[key] : ''))
  return newObj
}

export const countAffectedRows = (stagedIds: string[]): number => {
  const affectedRows = Object.values(stagedIds).reduce((rows, id) => {
    rows[removeActionTypeFromId(id)] = ''
    return rows
  }, {} as { [key: string]: any })
  return Object.keys(affectedRows).length
}

export const removeActionTypeFromId = (id: string) => {
  return id.replace(/\[.*\]/g, '')
}

export async function saveActions(
  assortmentType: string,
  modificationUrl: string,
  toBeSaved: { [key: string]: ShapeAction | BarcodeAction | SonderpfandAction | CrateAction },
  staged: { [key: string]: ShapeAction | BarcodeAction | SonderpfandAction | CrateAction | undefined }
) {
  const merged = { ...toBeSaved, ...withoutUndefinedActions(staged) }
  const modifications = Object.entries(merged)
    .map(([id, action]) => {
      if (action && action.type !== 'UNSET') {
        return {
          referenceObjectId: removeActionTypeFromId(id),
          actionType: action.type.toString(),
          actionValue: action.value ? action.value.toString() : '',
          allowedOverride: action.allowOverride ?? true,
        }
      }
      return null
    })
    .filter((modification) => modification != null)
  return authenticatedFetch(modificationUrl, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        assortmentType: assortmentType,
        modifications: modifications || [],
      },
    }),
  })
}
