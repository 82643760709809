import React, { useState } from 'react'

import { Container } from 'styles/PageLayout'
import styled from 'styled-components'
import { FileUploadResult } from 'domain/DTO'
import { ListDiffDetails } from 'components/diff/ListDiffDetails'
import { getIdField, getListDiff } from 'components/diff/AssortmentDiff'
import { ListDiffSummary } from 'components/diff/ListDiffSummary'

const ActionButtons = styled.div`
  margin-bottom: 2rem;
`

const ShowDiffButton = ({ showDiff, toggleShowDiff }: { showDiff: boolean; toggleShowDiff: () => void }) => {
  const text = showDiff ? 'Hide diff' : 'Show complete diff'
  return (
    <button className="btn ml-sm" onClick={() => toggleShowDiff()}>
      {text}
    </button>
  )
}

type Props = {
  result: FileUploadResult
  latestFileVersion: string | null
  onApply: () => Promise<void>
}

export const FileUploadResultDialog = ({ result, latestFileVersion, onApply }: Props) => {
  const [isApplying, setIsApplying] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  const generalWarnings = result.messages.filter((m) => m.severity === 'WARNING' && m.entityId === null)
  const generalErrors = result.messages.filter((m) => m.severity === 'ERROR' && m.entityId === null)
  const scrollingClassNames = showDetails ? 'overflow-y-auto h-[80vh]' : ''
  return (
    <Container className={scrollingClassNames}>
      {result.stagedFile ? (
        <>
          <h2>
            {latestFileVersion
              ? `Comparing current version '${latestFileVersion}' to staged version '${result.stagedFile.parsedVersion}'`
              : `'Staged the first file for this file stream, version: ${result.stagedFile.parsedVersion}'`}
          </h2>
          {generalWarnings.length > 0 ? (
            <ul>
              <li>Warnings:</li>
              <ul>
                {generalWarnings.map((w) => (
                  <li key={w.message}>{w.message}</li>
                ))}
              </ul>
            </ul>
          ) : null}
          {generalErrors.length > 0 ? (
            <ul>
              <li>Errors:</li>
              <ul>
                {generalErrors.map((err) => (
                  <li key={err.message}>{err.message}</li>
                ))}
              </ul>
            </ul>
          ) : null}

          {showDetails ? (
            <ListDiffDetails
              diff={getListDiff(result.diff ?? {})}
              idField={getIdField(result.diff ?? {})}
              messages={result.messages}
            />
          ) : (
            <ListDiffSummary diff={getListDiff(result.diff ?? {})} messages={result.messages} />
          )}

          <ActionButtons>
            <button
              className="btn btn-primary-dark py-sm"
              style={{ display: 'inline-block' }}
              disabled={result.stagedFile.latestFileId === -1 || isApplying}
              onClick={() => {
                setIsApplying(true)
                onApply()
              }}
            >
              Apply
            </button>
            <ShowDiffButton showDiff={showDetails} toggleShowDiff={() => setShowDetails(!showDetails)} />
          </ActionButtons>
        </>
      ) : (
        <>
          {generalWarnings.length > 0 ? (
            <ul>
              <li>Warnings:</li>
              <ul>
                {generalWarnings.map((w) => (
                  <li key={w.message}>{w.message}</li>
                ))}
              </ul>
            </ul>
          ) : null}
          {generalErrors.length > 0 ? (
            <ul>
              <li>Errors:</li>
              <ul>
                {generalErrors.map((err) => (
                  <li key={err.message}>{err.message}</li>
                ))}
              </ul>
            </ul>
          ) : null}
        </>
      )}
    </Container>
  )
}
