import React from 'react'
import { NodeStatus, PathScope, StructureNode } from 'lib/InstallationScope'

const typeToDisplayMap: { [key: string]: string } = {
  locationId: 'Store',
  chainId: 'Organization',
  subChainId: 'Sub Organization',
  country: 'Country',
  installationId: 'Machine',
}

const findDisplayValueForPath = (path: string, data: StructureNode[]): string => {
  const parts = path.split(';')

  const node = parts.reduce(
    (acc: StructureNode | undefined, part) =>
      acc ? acc.children.find((node) => node.type + ':' + node.id === part) : undefined,
    {
      type: 'root',
      display: 'root',
      deepChildCount: 0,
      id: 'root',
      children: data,
      status: NodeStatus.NONE,
      conflictCommons: [],
    }
  )

  return node ? `${typeToDisplayMap[node.type]}: ${node.display}` : 'UNKNOWN NODE IN TREE'
}

type Props = {
  scopes: PathScope[]
  data: StructureNode[]
}

export const ScopeSummary = ({ scopes, data }: Props) => {
  return (
    <div>
      {scopes.length > 0 ? 'Allowing:' : null}
      <ul className="pl-xl">
        {scopes.map((scope) => {
          const displayValue = scope.include.map((include) => findDisplayValueForPath(include, data)).join(', ')
          return (
            <li className="text-green" key={scope.include.join(';')}>
              {displayValue}
              {scope.exclude.length > 0 ? (
                <ul className="pl-xl">
                  <li className="text-black" key="except-header">
                    Except:
                  </li>
                  {scope.exclude.map((exclude) => (
                    <li className="text-red" key={exclude}>
                      {findDisplayValueForPath(exclude, data)}
                    </li>
                  ))}
                </ul>
              ) : null}
              {scope.unknownIncludes && scope.unknownIncludes.length > 0 ? (
                <ul className="pl-xl">
                  <li className="text-black" key="unknown-header">
                    Unknown includes:
                  </li>
                  {scope.unknownIncludes.map((unknown) => (
                    <li className="text-red" key={unknown}>
                      {unknown}
                    </li>
                  ))}
                </ul>
              ) : null}
              {scope.unknownExcludes && scope.unknownExcludes.length > 0 ? (
                <ul className="pl-xl">
                  <li className="text-black" key="unknown-header">
                    Unknown excludes:
                  </li>
                  {scope.unknownExcludes.map((unknown) => (
                    <li className="text-red" key={unknown}>
                      {unknown}
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
