import React, { useState } from 'react'
import styled from 'styled-components'
import {
  NodeStatus,
  PathScope,
  ScopeFilterConfiguration,
  ScopeFilterWithStatus,
  StructureNode,
} from 'lib/InstallationScope'
import { Checkbox } from './Checkbox'
import { ArrowDownIcon, ArrowRightIcon } from '../../icons'

type NodeProps = {
  node: StructureNode
  path: string
  scopes: PathScope[]
  scopeFilterConfiguration: ScopeFilterConfiguration
  parentStatus: NodeStatus.INCLUDED | NodeStatus.EXCLUDED | NodeStatus.NONE
  searchHit: string[]
  exclude: (entityPath: string) => void
  include: (entityPath: string) => void
}

const IconContainer = styled.div`
  cursor: pointer;
  display: inline-block;
`

const Li = styled.li`
  list-style: none outside none;
`

const Ul = styled.ul`
  list-style: none outside none;
  margin: 0;
  padding-left: 22px;
`

const Row = styled.div`
  display: flex;

  > * {
    align-self: center;
  }
`

function getNodeStatus(
  scopes: PathScope[],
  nodeString: string,
  parentStatus: NodeStatus,
  path: string
): NodeStatus.INCLUDED | NodeStatus.EXCLUDED | NodeStatus.NONE {
  const isParentIncluded = parentStatus === NodeStatus.INCLUDED
  const isNodeExcluded =
    parentStatus === NodeStatus.EXCLUDED || scopes.some((scope) => scope.exclude.some((a) => a === path))
  const isNodeIncluded =
    !isNodeExcluded && (isParentIncluded || scopes.some((scope) => scope.include.some((a) => a === path)))

  if (isNodeExcluded) {
    return NodeStatus.EXCLUDED
  } else if (isNodeIncluded) {
    return NodeStatus.INCLUDED
  } else {
    return NodeStatus.NONE
  }
}

function getCheckboxStatus(
  scopes: PathScope[],
  nodeString: string,
  nodeStatus: NodeStatus.INCLUDED | NodeStatus.EXCLUDED | NodeStatus.NONE,
  path: string
): NodeStatus {
  if (nodeStatus === NodeStatus.EXCLUDED) {
    return NodeStatus.EXCLUDED
  } else {
    const isChildDenied = scopes.some((scope) => scope.exclude.some((a) => a !== path && a.includes(nodeString)))
    const isChildAllowed = scopes.some((scope) => scope.include.some((a) => a !== path && a.includes(nodeString)))
    if (isChildAllowed || isChildDenied) {
      return NodeStatus.PARTIAL
    } else {
      return nodeStatus
    }
  }
}

const IconPlaceHolder = styled.div`
  display: inline-block;
  height: 28px;
  width: 28px;
`

function isDisabledByFilter(node: StructureNode, filters: ScopeFilterWithStatus[]): boolean {
  if (node.type !== 'installationId') {
    return false
  }
  const filterableProperties: { [type: string]: string | undefined } = node.filterableProperties ?? {}
  const excludes = filters
    .filter((filter) => filter.status === NodeStatus.EXCLUDED)
    .map((filter) => ({ type: filter.type, value: filter.value }))
  const excluded: boolean = excludes.some((exclude) => filterableProperties[exclude.type] === exclude.value)
  const includes = filters
    .filter((filter) => filter.status === NodeStatus.INCLUDED)
    .map((filter) => ({ type: filter.type, value: filter.value }))
  const included: boolean = includes.every((include) => filterableProperties[include.type] === include.value)
  return excluded || !included
}

const TreeNode = ({
  node,
  path,
  scopes,
  scopeFilterConfiguration,
  parentStatus,
  searchHit,
  exclude,
  include,
}: NodeProps) => {
  const nodeString = `${node.type}:${node.id}`
  const isSearchMatch = searchHit.find((searchHit) => searchHit.includes(nodeString))
  const [isOpen, setIsOpen] = useState(node.id === '' && node.children.length > 0)
  const isActuallyOpen = isSearchMatch || isOpen
  const isParentExcluded = parentStatus === NodeStatus.EXCLUDED
  const nodeStatus = getNodeStatus(scopes, nodeString, parentStatus, path)
  const checkboxStatus = getCheckboxStatus(scopes, nodeString, nodeStatus, path)
  const conflictCommonName = node.conflictCommons?.length > 0 ? node.conflictCommons : undefined
  const filterMode = scopeFilterConfiguration.isScopeFiltersEnabled()
  const disabledByFilter = isDisabledByFilter(node, scopeFilterConfiguration.scopeFilters)
  const disabledByConflict = !filterMode && !!conflictCommonName
  const checkboxDisabled = node.id === '' || isParentExcluded || disabledByConflict || disabledByFilter
  const display = node.display + (node.children.length > 0 ? ' [' + node.deepChildCount + ']' : '')

  return (
    <Li
      style={{ backgroundColor: !!conflictCommonName ? '#ffdbe6' : '' }}
      title={conflictCommonName ? 'Already assigned in Common: ' + conflictCommonName : undefined}
    >
      <Row>
        {node.children.length > 0 ? (
          <IconContainer onClick={() => setIsOpen(!isOpen)} role="expander">
            {isActuallyOpen ? <ArrowDownIcon /> : <ArrowRightIcon />}
          </IconContainer>
        ) : (
          <IconPlaceHolder />
        )}
        {node.type === 'region' || node.id === '' ? null : (
          <Checkbox
            status={checkboxStatus}
            disabled={checkboxDisabled}
            onChange={() => {
              if (nodeStatus === NodeStatus.INCLUDED) {
                exclude(path)
              } else {
                include(path)
              }
            }}
          />
        )}
        <span className="px-sm" style={{ backgroundColor: isSearchMatch ? '#fcf67f' : '' }}>
          {display}
        </span>
      </Row>
      {isActuallyOpen ? (
        <Ul>
          {node.children
            .sort((a, b) => (a.display + a.id > b.display + b.id ? 1 : -1))
            .map((child) => (
              <TreeNode
                key={child.id}
                node={child}
                path={path + ';' + child.type + ':' + child.id}
                scopes={scopes}
                scopeFilterConfiguration={scopeFilterConfiguration}
                parentStatus={nodeStatus}
                searchHit={searchHit}
                exclude={exclude}
                include={include}
              />
            ))}
        </Ul>
      ) : null}
    </Li>
  )
}

type TreeViewerProps = {
  scopes: PathScope[]
  exclude: (entityPath: string) => void
  include: (entityPath: string) => void
  nodes: StructureNode[]
  scopeFilterConfiguration: ScopeFilterConfiguration
  searchHit: string[]
}

export const TreeViewer = ({
  scopes,
  exclude,
  include,
  nodes,
  scopeFilterConfiguration,
  searchHit,
}: TreeViewerProps) => {
  return (
    <div>
      <Ul>
        {nodes.map((node) => (
          <TreeNode
            key={node.id}
            node={node}
            path={node.type + ':' + node.id}
            scopes={scopes}
            scopeFilterConfiguration={scopeFilterConfiguration}
            parentStatus={NodeStatus.NONE}
            searchHit={searchHit}
            include={include}
            exclude={exclude}
          />
        ))}
      </Ul>
    </div>
  )
}
