import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { ArrowDownIcon, ArrowUpIcon } from '../../icons'

export function useTableSorting<T>(
  columnStringExtractorMap: { [key: string]: (candidate: T) => string },
  defaultColumn?: string
) {
  const [[column, direction], setSorting] = useState<[string, 'ASC' | 'DESC']>([defaultColumn ?? '', 'ASC'])

  const sort = (array: T[]): T[] => {
    const extractor = columnStringExtractorMap[column]
    if (extractor) {
      return array.sort((a, b) => {
        const inverter = direction === 'DESC' ? 1 : -1
        return (extractor(a) > extractor(b) ? -1 : 1) * inverter
      })
    }
    return array
  }

  return {
    currentSort: {
      column,
      direction,
      setSorting,
    },
    sort,
  }
}

type SortableHeaderContentProps = {
  name: string
  currentSort: {
    column: string
    direction: 'ASC' | 'DESC'
    setSorting: (value: [string, 'ASC' | 'DESC']) => void
  }
  className?: string
}

type SortableHeaderProps = SortableHeaderContentProps & {
  minWidth?: string
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const FatText = styled.div`
  font-weight: 800;
  color: ${colors.PRIMARY_TEXT};
  cursor: pointer;
`

const IconPlaceHolder = styled.div`
  height: 20px;
  width: 20px;
`

export function SortableHeaderContent({ name, currentSort, className }: SortableHeaderContentProps) {
  return (
    <HeaderContainer className={className ?? ''}>
      <FatText
        onClick={() => {
          if (currentSort.column === name) {
            if (currentSort.direction === 'ASC') {
              currentSort.setSorting([name, 'DESC'])
            } else {
              currentSort.setSorting([name, 'ASC'])
            }
          } else {
            currentSort.setSorting([name, 'ASC'])
          }
        }}
      >
        {name}
      </FatText>
      {currentSort.column === name ? (
        currentSort.direction === 'ASC' ? (
          <ArrowDownIcon />
        ) : (
          <ArrowUpIcon />
        )
      ) : (
        <IconPlaceHolder />
      )}
    </HeaderContainer>
  )
}

export const SortableHeader = ({ name, currentSort, minWidth }: SortableHeaderProps) => {
  return (
    <th style={{ minWidth }}>
      <SortableHeaderContent name={name} currentSort={currentSort} />
    </th>
  )
}
