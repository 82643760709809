import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { NodeStatus } from '../../lib/InstallationScope'

const Checked = styled.div`
  display: inline;
  content: '';
  position: absolute;
  width: 9px;
  height: 6px;
  background: transparent;
  top: 6px;
  left: 6px;
  border: 3px solid white;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
`

const Indeterminate = styled.div`
  display: inline;
  content: '';
  position: absolute;
  width: 9px;
  background: transparent;
  top: 9px;
  left: 6px;
  border: 3px solid white;
  border-top: none;
  border-right: none;
  transform: rotate(0deg);
`

const Excluded = styled.div`
  display: inline;
  content: '';
  position: absolute;
  width: 9px;
  background: transparent;
  top: 9px;
  left: 6px;
  border: 3px solid white;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);

  &:after {
    display: inline;
    content: '';
    position: absolute;
    width: 9px;
    background: transparent;
    top: 0px;
    left: -3px;
    border: 3px solid white;
    border-top: none;
    border-right: none;
    transform: rotate(90deg);
  }
`

type BoxProps = { status: NodeStatus; disabled: boolean }

function getBackgroundColor(status: string): string {
  switch (status) {
    case NodeStatus.INCLUDED:
      return colors.GREEN
    case NodeStatus.PARTIAL:
      return colors.YELLOW
    case NodeStatus.NONE:
      return colors.WHITE
    case NodeStatus.EXCLUDED:
      return colors.RED
    default:
      return colors.PRIMARY
  }
}

const Box = styled.div.attrs<BoxProps>((props) => ({ 'aria-label': props.status }))`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: ${(props: BoxProps) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => (props.disabled ? '#CCC' : getBackgroundColor(props.status))};
  border: ${(props) =>
    props.status === NodeStatus.NONE || props.disabled ? '1px solid ' + colors.SECONDARY_TEXT : ''};
  border-radius: 4px;
  left: -4px;
`

type Props = {
  status: NodeStatus
  onChange: () => void
  disabled: boolean
  title?: string
}

export const Checkbox = ({ status, onChange, disabled, title }: Props) => {
  return (
    <Box
      status={status}
      disabled={disabled}
      onClick={() => (disabled ? null : onChange())}
      role={'checkbox'}
      title={title}
    >
      {disabled
        ? null
        : (status === NodeStatus.INCLUDED && <Checked />) ||
          (status === NodeStatus.PARTIAL && <Indeterminate />) ||
          (status === NodeStatus.EXCLUDED && <Excluded />) ||
          null}
    </Box>
  )
}
