import React from 'react'
import { JobForInstallation } from 'domain/DTO'
import styled from 'styled-components'
import { EnvToUrlMap } from 'lib'
import { PagedTable } from '@tomra/react-table'

type Props = {
  history: JobForInstallation[]
  serialNumber: string
}

const JobLink = styled.a`
  font-weight: bold;
`

export const JobHistoryTable = ({ history, serialNumber }: Props) => {
  return (
    <PagedTable
      data={history}
      columnHeaders={['Link to TomraConnect', 'Contents', 'Status', 'Started', 'Concluded']}
      rowFactory={(jobHistory) => {
        const envUrl = EnvToUrlMap[jobHistory.environment]
        return (
          <tr key={jobHistory.jobId}>
            <td>
              <JobLink
                target="_blank"
                href={`https://${envUrl}.tomraconnect.com/page/installation/update/view/redirect?serial=${serialNumber}&updateId=${jobHistory.jobId}`}
              >
                {'↗️ Inspect Update'}
              </JobLink>
            </td>
            <td>{renderFilesString(jobHistory)}</td>
            <td>{getEmojiForStatus(jobHistory.status)}</td>
            <td>
              {new Date(jobHistory.created).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </td>
            <td>
              {jobHistory.concluded
                ? new Date(jobHistory.concluded).toLocaleString([], {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : 'Ongoing'}
            </td>
          </tr>
        )
      }}
      initialRecordsPerPage={100}
      recordsPerPageOptions={[10, 20, 50, 100]}
    />
  )
}

function renderFilesString(job: JobForInstallation) {
  const list = [
    job.updatesShapeFile ? 'SHAPE' : '',
    job.updatesBarcodeFile ? 'BARCODE' : '',
    job.updatesCrateFile ? 'CRATE' : '',
    job.updatesLocalCrateFile ? 'LOCAL CRATE' : '',
  ].filter((file) => file !== '')
  return list.join(', ')
}

function getEmojiForStatus(status: string) {
  switch (status) {
    case 'COMPLETED':
      return '✔️ Completed'
    case 'RECOVERED':
    case 'FAILED':
      return '🚨 Failed'
    case 'CANCELLED':
      return '⚠️ Cancelled'
    case 'QUEUED':
      return '⏲️ Queued'
    default:
      return '⏳ Ongoing'
  }
}
