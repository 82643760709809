import styled from 'styled-components'
import colors from './colors'
import { spacing } from './index'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  > div {
    flex: 1;
  }
  :first-child {
    margin-right: ${spacing.md};
  }
  :last-child {
    margin-left: ${spacing.md};
  }
`

export const Label = styled.label`
  display: block;
  > span {
    font-size: smaller;
    color: ${colors.PRIMARY_TEXT};
  }
`

export const CBLabel = styled.label`
  > span {
    color: ${colors.PRIMARY_TEXT};
  }
  > input[type='checkbox'] {
    margin-right: ${spacing.sm};
  }
`
styled(Label)``

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${spacing.md};
  margin-left: ${spacing.md};
  max-width: 16000px;
  > *:not(:last-child) {
    margin-right: ${spacing.md};
  }
`

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: ${spacing.md};
  margin-left: ${spacing.md};
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${spacing.md};
  }
`

export const ButtonContainer = styled(Flex)`
  width: 100%;
`

export const ContentOnEdges = styled(ButtonContainer)`
  justify-content: space-between;
`

export const Left = styled(Flex)``

export const Right = styled(Flex)``
