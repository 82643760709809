import React from 'react'
import { ScopeConflict } from '../domain/DTO'
import { Link } from 'react-router-dom'

type Props = {
  scopeConflicts: ScopeConflict[]
}
export const ConflictSummary = ({ scopeConflicts }: Props) => {
  return (
    <div className={'alert alert-danger my-sm text-left'}>
      {'There are conflicts for the following installations:'}
      <ul>
        {scopeConflicts.map((c) => (
          <ConflictItem key={c.conflictingCommonAssortments.toString()} conflict={c} />
        ))}
      </ul>
    </div>
  )
}

const ConflictItem = ({ conflict }: { conflict: ScopeConflict }) => {
  const installation = conflict.installation
  const installationLink = `/installation/${installation.installationId}`
  const commons = conflict.conflictingCommonAssortments
  return (
    <li key={installation.installationId}>
      <Link to={installationLink} className={'link'}>
        {installation.serialNumber}
      </Link>
      {' (' + installation.country + ' > '}
      {installation.chainName ? installation.chainName + ' > ' : ''}
      {installation.subChainName ? installation.subChainName + ' > ' : ''}
      {installation.region ? installation.region + ' > ' : ''}
      {installation.locationName + ')'}
      {': '}
      {commons.map((c) => {
        const commonLink = `/common-assortment/${c.commonAssortmentId}`
        return (
          <Link to={commonLink} className={'link'} key={commonLink}>
            {'<' + c.name + '> '}
          </Link>
        )
      })}
    </li>
  )
}
