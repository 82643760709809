import React, { useState } from 'react'
import { FormLayout } from 'styles/FormLayout'
import { Label } from 'styles/PageLayout'
import {
  BarcodeAction,
  ClosedActionType,
  RejectActionType,
  SonderpfandAction,
  isSonderpfandAction,
  Config,
} from 'domain/Assortment'
import { SelectAction } from '../CommonComponents'

export type DisabledBarcodeActions = {
  closedActionDisabled: boolean
  rejectActionDisabled: boolean
}

export type BarcodeActionSnapshot = {
  closedAction?: BarcodeAction | SonderpfandAction
  rejectAction?: BarcodeAction | SonderpfandAction
}

type Props = {
  config: Config
  id: string
  snapshot: BarcodeActionSnapshot
  disabled: DisabledBarcodeActions
  sonderpfandAction?: SonderpfandAction
  onApply: (id: string, snapshot: BarcodeActionSnapshot) => void
}

export const BarcodeActionDialog = ({ config, id, snapshot, disabled, sonderpfandAction, onApply }: Props) => {
  const [closedAction, setCloseAction] = useState(snapshot.closedAction)
  const [rejectAction, setRejectAction] = useState(snapshot.rejectAction)

  return (
    <div className="card">
      <FormLayout.Content>
        <h3>Active actions</h3>
        <SelectAction
          config={config}
          label={'CLOSE action:'}
          type={closedAction?.type}
          disabled={disabled.closedActionDisabled}
          allowOverride={closedAction?.allowOverride}
          options={[ClosedActionType.SET, ClosedActionType.CLEAR]}
          onTypeChanged={(value) => setCloseAction({ ...closedAction, type: value as ClosedActionType })}
          onAllowOverrideChanged={(allow) => {
            if (closedAction) {
              setCloseAction({ ...closedAction, allowOverride: allow })
            }
          }}
        />
        <SelectAction
          config={config}
          label={'REJECT action:'}
          type={rejectAction?.type}
          disabled={disabled.rejectActionDisabled}
          allowOverride={rejectAction?.allowOverride}
          options={[RejectActionType.SET]}
          onTypeChanged={(value) => setRejectAction({ ...rejectAction, type: value as RejectActionType })}
          onAllowOverrideChanged={(allow) => {
            if (rejectAction) {
              setRejectAction({ ...rejectAction, allowOverride: allow })
            }
          }}
        >
          {isSonderpfandAction(sonderpfandAction?.type) && <Label>Remove Sonderpfand action to enable</Label>}
        </SelectAction>
        <FormLayout.FooterContainer>
          <FormLayout.Footer>
            <button
              className="btn btn-primary-dark py-sm"
              onClick={() => {
                onApply(id, { closedAction: closedAction, rejectAction: rejectAction })
              }}
            >
              <span>Save</span>
            </button>
          </FormLayout.Footer>
        </FormLayout.FooterContainer>
      </FormLayout.Content>
    </div>
  )
}
