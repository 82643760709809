import React, { useState } from 'react'
import { Dialog } from '../Dialog'
import styled from 'styled-components'
import {
  UNSET,
  ShapeAction,
  originalHasChanged,
  CrateAction,
  BarcodeAction,
  ActionNames,
  SonderpfandAction,
  Config,
  isCommon,
} from 'domain/Assortment'
import { Label, InnerContainer } from 'styles/PageLayout'
import { Select } from 'components/Select'
import { AddCircleIcon, EditIcon } from '../../icons'

export const Bold = styled.span`
  font-weight: bold;
`
export const Strikethrough = styled.span`
  text-decoration: line-through;
`

type EditableActionsProps = {
  onClick: () => void
}

export const EditableActions = ({ onClick, children }: React.PropsWithChildren<EditableActionsProps>) => {
  if (React.Children.count(children) !== 0) {
    return (
      <div className="flex items-center">
        <div>{children}</div>
        <button className="mx-sm" title="edit" onClick={onClick}>
          <EditIcon />
        </button>
      </div>
    )
  } else {
    return (
      <button className="mx-sm" title="add" onClick={onClick}>
        <AddCircleIcon color="none" />
      </button>
    )
  }
}

export const FinalStatus = ({ ok }: { ok: boolean }) => {
  if (ok) {
    return (
      <span role="img" aria-label="Accepted">
        🟢
      </span>
    )
  }
  return (
    <span role="img" aria-label="Rejected">
      🔴
    </span>
  )
}

export type ActionProps = {
  action: ShapeAction | CrateAction | BarcodeAction | SonderpfandAction | undefined
}

export const Action = ({ action }: ActionProps) => {
  return <div>{print(action)}</div>
}

type StagedActionProps = {
  original?: ShapeAction | CrateAction | BarcodeAction | SonderpfandAction
  staged?: ShapeAction | CrateAction | BarcodeAction | SonderpfandAction
}

export const StagedAction = ({ original, staged }: StagedActionProps) => {
  const showSaved = !!original
  const hasChanged = originalHasChanged(staged, original)
  const showStaged = !!staged?.type && staged?.type !== UNSET

  return (
    <div>
      <div>
        {showSaved && !hasChanged && <span>{print(original)}</span>}
        {showSaved && hasChanged && (
          <Bold>
            <Strikethrough>{print(original)}</Strikethrough> (staged)
          </Bold>
        )}
      </div>
      {showStaged && (
        <div>
          <Bold>{print(staged)} (staged)</Bold>
        </div>
      )}
    </div>
  )
}

const print = (action: ShapeAction | CrateAction | BarcodeAction | SonderpfandAction | undefined) => {
  if (action) {
    return (action.allowOverride === false ? '🔒 ' : '') + ActionNames[action.type] + ' ' + (action.value ?? '')
  }
  return ''
}

type LockActionProps = {
  allowed: boolean
  onClick: (allowOverride: boolean) => void
}

const LockContainer = styled.div`
  margin-left: 20px;
`

const LockedSpan = styled.span`
  cursor: default;
  margin-left: 4px;
`

export const LockAction = ({ allowed, onClick }: LockActionProps) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  return (
    <LockContainer title={'Denies creating overrides for an installation for this action'}>
      <span>Override not allowed </span>
      <input
        type="checkbox"
        className="checkbox align-middle"
        checked={!allowed}
        onChange={() => {
          if (!allowed) {
            onClick(true)
          } else {
            setShowConfirmDialog(true)
          }
        }}
      />
      {showConfirmDialog && (
        <Dialog
          onClose={() => {
            setShowConfirmDialog(false)
          }}
        >
          <ConfirmDenyOverride
            onConfirm={() => {
              onClick(false)
              setShowConfirmDialog(false)
            }}
          />
        </Dialog>
      )}
    </LockContainer>
  )
}

type ConfirmDenyOverrideProps = {
  onConfirm: () => void
}

const ConfirmDenyOverride = ({ onConfirm }: ConfirmDenyOverrideProps) => {
  return (
    <div className="card">
      <div style={{ maxWidth: 600, display: 'flex', flexDirection: 'column', padding: 24, paddingRight: 36 }}>
        <div className="alert alert-warning mb-md mt-md">
          You are about to deny installation override for this action. Any existing installation overrides in conflict
          with this will be REMOVED upon applying.
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
          <button className="btn btn-primary-dark py-sm" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export const Locked = () => {
  return <LockedSpan>{'🔒'}</LockedSpan>
}

const InlineSelectContainer = styled.div`
  > div {
    display: inline-block;
  }
`

const PlaceholderBlock = styled.div`
  width: 40px;
`

type SelectActionProps = {
  config: Config
  label: string
  options: string[]
  type?: string
  disabled: boolean
  allowOverride?: boolean
  overrideAllowed?: boolean
  onTypeChanged: (value: string) => void
  onAllowOverrideChanged: (value: boolean) => void
}

export const SelectAction = ({
  config,
  label,
  options,
  type,
  disabled,
  allowOverride,
  onTypeChanged,
  onAllowOverrideChanged,
  children,
}: React.PropsWithChildren<SelectActionProps>) => {
  return (
    <InnerContainer>
      <Label style={{ width: 150 }}>{label}</Label>
      <InlineSelectContainer>
        <Select value={type} disabled={disabled} onChange={onTypeChanged} width={180}>
          <option value={UNSET} />
          {options.map((option) => (
            <option key={option} value={option}>
              {ActionNames[option]}
            </option>
          ))}
        </Select>
        {disabled && <Locked />}
        {children ? children : <PlaceholderBlock />}
      </InlineSelectContainer>
      {isCommon(config) && isSet(type) && (
        <LockAction allowed={allowOverride ?? true} onClick={onAllowOverrideChanged} />
      )}
    </InnerContainer>
  )
}

type SelectActionWithValueProps = {
  config: Config
  label: string
  type?: string
  disabled: boolean
  value?: string
  allowOverride?: boolean
  maxValue: number
  options: string[]
  onTypeChanged: (value: string) => void
  onValueChanged: (value: string) => void
  onAllowOverrideChanged: (value: boolean) => void
}

export const SelectActionWithValue = ({
  config,
  label,
  type,
  disabled,
  value,
  allowOverride,
  maxValue,
  options,
  onTypeChanged,
  onValueChanged,
  onAllowOverrideChanged,
}: SelectActionWithValueProps) => {
  return (
    <SelectAction
      config={config}
      label={label}
      type={type}
      disabled={disabled}
      allowOverride={allowOverride}
      options={options}
      onTypeChanged={onTypeChanged}
      onAllowOverrideChanged={onAllowOverrideChanged}
    >
      {!!type && type !== UNSET && (
        <input
          type="number"
          min={0}
          max={maxValue}
          placeholder={'Between 0 and ' + maxValue}
          className="textfield"
          value={value}
          onChange={(e) => {
            if (e.target.value === '' || parseInt(e.target.value, 10) <= maxValue) {
              onValueChanged(e.target.value)
            }
          }}
        />
      )}
    </SelectAction>
  )
}

function isSet(type: string | undefined) {
  return !!type && type !== 'UNSET'
}
