import React from 'react'
import { Dictionary, isKeyValue, isValue, KeyValue, ListDiff, ValidationMessage } from 'domain/DTO'
import { Label } from 'styles/PageLayout'
import { DiffHoverTooltip } from './DiffHoverTooltip'
import { colors } from 'styles'
import styled from 'styled-components'
import { getStatusEmoji } from 'domain/FileStatus'
import { SortableHeaderContent, useTableSorting } from 'components/assortment/TableColumnSort'
import { padNumberToString } from 'lib/StringUtilities'
import { PagedTable } from '@tomra/react-table'

const DiffTableContainer = styled.div`
  position: relative;
  width: 100%
  margin: 1rem;
  z-index: 1;
`

type DiffTooltipProps = {
  from: Dictionary | null
  to: Dictionary | null
  columns: string[]
}

const DiffTooltip = ({ from, to, columns }: DiffTooltipProps) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Field</th>
          <th>From</th>
          <th>To</th>
        </tr>
      </thead>
      <tbody>
        {columns.map((field, i) => {
          const fromField = fieldToString(from?.[field] ?? '')
          const toField = fieldToString(to?.[field] ?? '')
          const equal = fromField === toField

          return (
            <tr key={i}>
              <td>{field}</td>
              <td style={{ color: equal ? 'inherit' : colors.RED }}>{fromField}</td>
              <td style={{ color: equal ? 'inherit' : colors.GREEN }}>{toField}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function fieldToString(field: number | string | KeyValue | Array<number | string | KeyValue>): string {
  if (isKeyValue(field)) {
    return `${field.key}=${field.value}`
  } else if (isValue(field)) {
    return `${field.value}`
  } else if (Array.isArray(field)) {
    return field.map((element) => fieldToString(element)).join(', ')
  } else {
    return field.toString()
  }
}

type DiffType = 'ADD' | 'REMOVE' | 'UPDATE'

type DiffRow = {
  id: string
  type: DiffType
  from: Dictionary | null
  to: Dictionary | null
}

type Props = {
  diff: ListDiff[]
  idField: string[]
  messages: ValidationMessage[]
}

export const ListDiffDetails = ({ diff, idField, messages }: Props) => {
  const columnName = idField[0].charAt(0).toUpperCase() + idField[0].slice(1)
  const { currentSort, sort } = useTableSorting<DiffRow>(
    {
      [columnName]: (row) => row.id,
      Type: (row) => row.type,
      Messages: (row) => padNumberToString(messages.filter((m) => m.entityId === row.id).length, '000'),
    },
    columnName
  )

  const addedDiffRows: DiffRow[] = diff.flatMap((value, index) =>
    value.added.map((added) => ({
      id: added[idField[index]]?.toString() ?? 'unknown',
      type: 'ADD',
      from: null,
      to: added,
    }))
  )
  const removedDiffRows: DiffRow[] = diff.flatMap((value, index) =>
    value.removed.map((removed) => ({
      id: removed[idField[index]]?.toString() ?? 'unknown',
      type: 'REMOVE',
      from: removed,
      to: null,
    }))
  )
  const updatedDiffRows: DiffRow[] = diff.flatMap((value, index) =>
    value.updated.map((updated) => ({
      id: updated.oldValue[idField[index]]?.toString() ?? 'unknown',
      type: 'UPDATE',
      from: updated.oldValue,
      to: updated.newValue,
    }))
  )

  const diffRows = [...addedDiffRows, ...removedDiffRows, ...updatedDiffRows].sort((a, b) => a.id.localeCompare(b.id))

  return (
    <DiffTableContainer>
      <PagedTable
        data={sort(diffRows)}
        columnHeaders={[
          <div style={{ minWidth: '15em' }}>
            <SortableHeaderContent currentSort={currentSort} name={columnName} />
          </div>,
          <div style={{ minWidth: '10em' }}>
            <SortableHeaderContent currentSort={currentSort} name={'Type'} />
          </div>,
          'Diff',
          <div style={{ minWidth: '10em' }}>
            <SortableHeaderContent currentSort={currentSort} name={'Messages'} />
          </div>,
        ]}
        rowFactory={(row) => {
          const applicableMessages: ValidationMessage[] = messages.filter((m) => m.entityId === row.id)
          const columns = Object.keys(row?.from ?? row?.to ?? {}).filter((key) => idField.indexOf(key) === -1)
          return (
            <tr key={row.id} data-testid={row.id}>
              <td>{row.id}</td>
              <td>{row.type}</td>
              <td style={{ minWidth: '10em' }}>
                <Label>
                  <DiffHoverTooltip>
                    <DiffTooltip from={row.from} to={row.to} columns={columns} />
                  </DiffHoverTooltip>
                </Label>
              </td>
              <td>
                {applicableMessages.length > 0 ? (
                  <Label data-testid={'messages'}>
                    <DiffHoverTooltip>
                      <ul style={{ listStyle: 'none', padding: '10px' }}>
                        {applicableMessages.map((m) => (
                          <li key={m.message}>{`${getStatusEmoji(m.severity)} ${m.message}`}</li>
                        ))}
                      </ul>
                    </DiffHoverTooltip>
                    <span style={{ verticalAlign: 'middle', fontSize: '20px', marginLeft: '10px' }}>
                      {applicableMessages.some((m) => m.severity === 'ERROR') ? getStatusEmoji('ERROR') : ''}
                      {applicableMessages.some((m) => m.severity === 'WARNING') ? getStatusEmoji('WARNING') : ''}
                    </span>
                  </Label>
                ) : null}
              </td>
            </tr>
          )
        }}
        initialRecordsPerPage={10}
        recordsPerPageOptions={[10, 20, 50, 100]}
        className="table"
      />
    </DiffTableContainer>
  )
}
