import React from 'react'
import { CommonsAndInstallationsContextData } from './DTO'
import { notAsked, RD } from 'srd'
import { HttpError } from '../lib/RemoteData'

export type CommonInstallationData = {
  data: RD<HttpError, CommonsAndInstallationsContextData>
  refresh: () => void
}

export const CommonsAndInstallationsContext = React.createContext<CommonInstallationData>({
  refresh: () => null,
  data: notAsked(),
})
