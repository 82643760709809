import React, { useState } from 'react'
import { Modification, ShapeFile, CrateFile, BarcodeAndSonderpfand, JobForInstallation, CommonConfig } from 'domain/DTO'
import { useRemoteData } from 'lib/RemoteData'
import { API_HOST } from 'lib'
import { BarcodeTable } from './barcode/BarcodeTable'
import { ShapeTable } from './shape/ShapeTable'
import { CrateTable } from './crate/CrateTable'
import { Config } from 'domain/Assortment'
import { JobHistoryTable } from 'pages/installations/components/JobHistoryTable'
import { RD, SRD } from 'srd'
import { HttpError } from '../../lib/RemoteData'

type CommonProps = {
  common: CommonConfig
}

export const CommonAssortmentDatabase = ({ common }: CommonProps) => {
  const commonId = common.commonAssortmentId
  const { data: barcode } = useRemoteData<BarcodeAndSonderpfand>(`${API_HOST}/v1/assortment/${commonId}/BARCODE`)
  const { data: commonBarcode, refresh: refreshBarcode } = useRemoteData<Modification[]>(
    `${API_HOST}/v1/common/${commonId}/BARCODE/modifications`
  )

  const { data: shape } = useRemoteData<ShapeFile>(`${API_HOST}/v1/assortment/${commonId}/SHAPE`)
  const { data: commonShape, refresh: refreshShape } = useRemoteData<Modification[]>(
    `${API_HOST}/v1/common/${commonId}/SHAPE/modifications`
  )

  const { data: crate } = useRemoteData<CrateFile>(`${API_HOST}/v1/assortment/${commonId}/CRATE`)
  const { data: commonCrate, refresh: refreshCrate } = useRemoteData<Modification[]>(
    `${API_HOST}/v1/common/${commonId}/CRATE/modifications`
  )

  const availableTypes = [
    common.shapeFileStream ? 'Shape' : '',
    common.barcodeFileStream ? 'Barcode' : '',
    common.crateFileStream ? 'Crate' : '',
  ].filter((t) => t !== '')

  const [activeTab, setActiveTab] = useState(availableTypes[0])

  return (
    <div>
      <div className="tabs w-6/12">
        {availableTypes.map((type) => {
          return (
            <button
              className={`tab ${activeTab === type ? 'active' : ''}`}
              onClick={() => {
                setActiveTab(type)
              }}
            >
              {type}
            </button>
          )
        })}
      </div>
      {activeTab === 'Shape' && (
        <ShapeTableWrapper
          config={'Common'}
          shape={shape}
          common={commonShape}
          commonId={commonId}
          refresh={refreshShape}
        />
      )}
      {activeTab === 'Barcode' && (
        <BarcodeTableWrapper
          config={'Common'}
          barcode={barcode}
          common={commonBarcode}
          commonId={commonId}
          refresh={refreshBarcode}
        />
      )}
      {activeTab === 'Crate' && (
        <CrateTableWrapper
          config={'Common'}
          crate={crate}
          common={commonCrate}
          commonId={commonId}
          refresh={refreshCrate}
        />
      )}
    </div>
  )
}

type InstallationProps = {
  commonId: string
  installationId: string
  history: JobForInstallation[]
  serialNumber: string
}

export const InstallationAssortmentDatabase = ({
  commonId,
  installationId,
  history,
  serialNumber,
}: InstallationProps) => {
  const { data: barcode } = useRemoteData<BarcodeAndSonderpfand>(`${API_HOST}/v1/assortment/${commonId}/BARCODE`)
  const { data: commonBarcode } = useRemoteData<Modification[]>(
    `${API_HOST}/v1/common/${commonId}/BARCODE/modifications`
  )
  const { data: overrideBarcode, refresh: refreshBarcode } = useRemoteData<Modification[]>(
    `${API_HOST}/v1/installation/${installationId}/BARCODE/modifications`
  )

  const { data: shape } = useRemoteData<ShapeFile>(`${API_HOST}/v1/assortment/${commonId}/SHAPE`)
  const { data: commonShape } = useRemoteData<Modification[]>(`${API_HOST}/v1/common/${commonId}/SHAPE/modifications`)
  const { data: overrideShape, refresh: refreshShape } = useRemoteData<Modification[]>(
    `${API_HOST}/v1/installation/${installationId}/SHAPE/modifications`
  )

  const { data: crate } = useRemoteData<CrateFile>(`${API_HOST}/v1/assortment/${commonId}/CRATE`)
  const { data: commonCrate } = useRemoteData<Modification[]>(`${API_HOST}/v1/common/${commonId}/CRATE/modifications`)
  const { data: overrideCrate, refresh: refreshCrate } = useRemoteData<Modification[]>(
    `${API_HOST}/v1/installation/${installationId}/CRATE/modifications`
  )

  const [activeTab, setActiveTab] = useState('SHAPE')

  return (
    <div>
      <div className="tabs w-full">
        <button
          className={`tab ${activeTab === 'SHAPE' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('SHAPE')
          }}
        >
          Shape
        </button>
        <button
          className={`tab ${activeTab === 'BARCODE' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('BARCODE')
          }}
        >
          Barcode
        </button>
        <button
          className={`tab ${activeTab === 'CRATE' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('CRATE')
          }}
        >
          Crate
        </button>
        <button
          className={`tab ${activeTab === 'HISTORY' ? 'active' : ''}`}
          onClick={() => {
            setActiveTab('HISTORY')
          }}
        >
          Job History
        </button>
      </div>
      {activeTab === 'SHAPE' && (
        <ShapeTableWrapper
          config={'Installation'}
          shape={shape}
          common={commonShape}
          override={overrideShape}
          installationId={installationId}
          commonId={commonId}
          refresh={refreshShape}
        />
      )}
      {activeTab === 'BARCODE' && (
        <BarcodeTableWrapper
          config={'Installation'}
          barcode={barcode}
          common={commonBarcode}
          override={overrideBarcode}
          installationId={installationId}
          commonId={commonId}
          refresh={refreshBarcode}
        />
      )}
      {activeTab === 'CRATE' && (
        <CrateTableWrapper
          config={'Installation'}
          crate={crate}
          common={commonCrate}
          override={overrideCrate}
          installationId={installationId}
          commonId={commonId}
          refresh={refreshCrate}
        />
      )}
      {activeTab === 'HISTORY' ? <JobHistoryTable history={history} serialNumber={serialNumber} /> : null}
    </div>
  )
}

export const paginationOptions = [10, 20, 50, 100]

type ShapeTableProps = {
  config: Config
  shape: RD<HttpError, ShapeFile>
  common: RD<HttpError, Modification[]>
  override?: RD<HttpError, Modification[]>
  commonId: string
  installationId?: string
  refresh: () => void
}

const ShapeTableWrapper = ({ config, shape, common, override, commonId, installationId, refresh }: ShapeTableProps) => {
  const combinedRemoteData = SRD.map2(
    (shape, common) => ({
      shape,
      common,
    }),
    shape,
    common
  )
  return SRD.match(
    {
      notAsked: () => <div>Empty</div>,
      loading: () => <div className="loadingSpinner centerAbsolute" />,
      failure: (err) => (
        <div className="alert alert-warning mb-md mt-md">
          No file seems to be uploaded to file stream
          <div>{err.body?.title}</div>
        </div>
      ),
      success: ({ shape, common }) => (
        <ShapeTable
          config={config}
          database={shape}
          commonModifications={common}
          installationModifications={override && SRD.isSuccess(override) ? override.data : []}
          modificationUrl={
            override
              ? `${API_HOST}/v1/installation/${installationId}/SHAPE/modifications`
              : `${API_HOST}/v1/common/${commonId}/SHAPE/modifications`
          }
          refreshModifications={refresh}
        />
      ),
    },
    combinedRemoteData
  )
}

type BarcodeTableProps = {
  config: Config
  barcode: RD<HttpError, BarcodeAndSonderpfand>
  common: RD<HttpError, Modification[]>
  override?: RD<HttpError, Modification[]>
  commonId: string
  installationId?: string
  refresh: () => void
}

const BarcodeTableWrapper = ({
  config,
  barcode,
  common,
  override,
  commonId,
  installationId,
  refresh,
}: BarcodeTableProps) => {
  const combinedRemoteData = SRD.map2((barcode, common) => ({ barcode, common }), barcode, common)
  return SRD.match(
    {
      notAsked: () => <div>Empty</div>,
      loading: () => <div className="loadingSpinner centerAbsolute" />,
      failure: (err) => (
        <div>
          <div className="alert alert-warning mb-md mt-md">
            No file seems to be uploaded to file stream
            <div>{err.body?.title}</div>
          </div>
        </div>
      ),
      success: ({ barcode, common }) => (
        <BarcodeTable
          config={config}
          database={barcode}
          commonModifications={common}
          installationModifications={override && SRD.isSuccess(override) ? override.data : []}
          modificationUrl={
            override
              ? `${API_HOST}/v1/installation/${installationId}/SHAPE/modifications`
              : `${API_HOST}/v1/common/${commonId}/SHAPE/modifications`
          }
          refreshModifications={refresh}
        />
      ),
    },
    combinedRemoteData
  )
}

type CrateTableProps = {
  config: Config
  crate: RD<HttpError, CrateFile>
  common: RD<HttpError, Modification[]>
  override?: RD<HttpError, Modification[]>
  commonId: string
  installationId?: string
  refresh: () => void
}

const CrateTableWrapper = ({ config, crate, common, override, commonId, installationId, refresh }: CrateTableProps) => {
  const combinedRemoteData = SRD.map2((crate, common) => ({ crate, common }), crate, common)
  return SRD.match(
    {
      notAsked: () => <div>Empty</div>,
      loading: () => <div className="loadingSpinner centerAbsolute" />,
      failure: (err) => (
        <div>
          <div className="alert alert-warning mb-md mt-md">
            No file seems to be uploaded to file stream
            <div>{err.body?.title}</div>
          </div>
        </div>
      ),
      success: ({ common, crate }) => (
        <CrateTable
          config={config}
          database={crate}
          commonModifications={common}
          installationModifications={override && SRD.isSuccess(override) ? override.data : []}
          modificationUrl={
            override
              ? `${API_HOST}/v1/installation/${installationId}/CRATE/modifications`
              : `${API_HOST}/v1/common/${commonId}/CRATE/modifications`
          }
          refreshModifications={refresh}
        />
      ),
    },
    combinedRemoteData
  )
}
