import React, { useContext, useState } from 'react'
import { useParams } from 'react-router'
import { useAutoRefresh, useRemoteData } from 'lib/RemoteData'
import { API_HOST } from 'lib'
import { Container } from 'styles/PageLayout'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { authenticatedFetch } from 'lib/authenticatedGet'
import { AssortmentStatus, Installation, JobForInstallation } from 'domain/DTO'
import { InstallationAssortmentDatabase } from 'components/assortment/AssortmentDatabase'
import { CommonsAndInstallationsContext } from 'domain/CommonsAndInstallationsContext'
import { fileTypesForCommon } from 'domain/FileStatus'
import { AssortmentStatusCell } from 'components/AssortmentStatusCell'
import { SRD } from 'srd'
import { logError } from '../../lib/logError'
import { ArrowRightIcon } from '../../icons'
import SearchIcon from '../../icons/SearchIcon'
import HomeIcon from '../../icons/HomeIcon'

type InstallationHeaderProps = {
  installation: Installation
  refresh: () => void
  history: JobForInstallation[]
  assortmentStatus: AssortmentStatus | undefined | null
  commonFileTypes: string[]
  commonIsLocked: boolean
}

const InstallationHeader = ({
  installation,
  refresh,
  history,
  assortmentStatus,
  commonFileTypes,
  commonIsLocked,
}: InstallationHeaderProps) => {
  const [cooldown, setCooldown] = useState(false)

  const triggerUploadAndRefresh = (installationId: string, refresh: () => void) => {
    const warningText =
      'The RVM you are about to update is linked to a locked CommonConfig. This CommonConfig may be locked because it currently has an inconsistency. Are you sure you want to update anyway?'
    if (commonIsLocked && !window.confirm(warningText)) {
      return
    }
    setCooldown(true)
    return authenticatedFetch(`${API_HOST}/v1/admin/trigger-generation/${installationId}`, { method: 'POST' })
      .catch((error) => logError(error))
      .then((_) => {
        setTimeout(() => {
          setCooldown(false)
          refresh()
        }, 3000)
      })
  }

  const hasAnyOngoingJobs = history.some((h) => h.concluded == null)

  const x = cooldown || hasAnyOngoingJobs

  return (
    <div>
      <h2 className="mx-sm">{installation.installationReference.serialNumber}</h2>
      <button
        className="btn btn-primary-dark py-sm"
        disabled={x}
        title={x ? 'Unable to Upload Latest when ongoing update' : ''}
        onClick={() => triggerUploadAndRefresh(installation.installationReference.installationId, refresh)}
      >
        Upload Latest
      </button>
      <table className="mt-md">
        <tbody>
          <tr>
            <td>
              <b>Chain / Sub Chain:</b>
            </td>
            <td> {installation.installationReference.chainName ?? installation.installationReference.subChainName}</td>
          </tr>
          <tr>
            <td>
              <b>Location:</b>
            </td>
            <td>{`${installation.installationReference.locationName} [${installation.installationReference.country}${
              installation.installationReference.region ? ', ' + installation.installationReference.region : ''
            }]`}</td>
          </tr>
          {installation.commonId && (
            <tr>
              <td>
                <b>Common:</b>
              </td>
              <td>
                <div>
                  <CommonLink to={`/common-assortment/${installation.commonId}`}>
                    {'📑 ' + installation.commonName}
                  </CommonLink>
                </div>
                <div>
                  <CommonLink to={`/installations?commonId=${installation.commonId}`} className="inline-flex mt-xs">
                    <SearchIcon color="none" size="18" />
                    <span className="ml-xs">{'Installations (' + installation.commonName + ')'}</span>
                  </CommonLink>
                </div>
              </td>
            </tr>
          )}
          {installation.commonId && (
            <React.Fragment>
              <tr>
                <td>
                  <b>Latest Status:</b>
                </td>
                <td>
                  {installation.machineConfig === null ? (
                    <div style={{ marginBottom: '10px', color: 'red' }}>
                      Missing machine configuration, unable to create correct update script. (Usually resolves with a
                      OPC Sync message)
                    </div>
                  ) : null}
                  {assortmentStatus ? (
                    <AssortmentStatusCell assortmentStatus={assortmentStatus} commonFileTypes={commonFileTypes} />
                  ) : null}
                </td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  )
}

const CommonLink = styled(Link)`
  font-weight: bold;
`

export const InstallationView = () => {
  const { id } = useParams<{ id: string }>()
  const { data: contextRemoteData, refresh: refreshContext } = useContext(CommonsAndInstallationsContext)
  const { data: installationRemoteData, refresh: refreshInstallation } = useRemoteData<Installation>(
    `${API_HOST}/v1/installation/${id}`
  )
  useAutoRefresh(refreshInstallation, 60000)

  const refresh = () => {
    refreshContext()
    refreshInstallation()
  }

  const combinedRemoteData = SRD.map2(
    (context, installation) => ({ context, installation }),
    contextRemoteData,
    installationRemoteData
  )

  return SRD.match(
    {
      notAsked: () => <div>Empty</div>,
      loading: () => (
        <div className="card">
          <div className="loadingSpinner" />
        </div>
      ),
      failure: (err) => (
        <div className="card">
          <div className="centerAbsolute">
            <div className="alert alert-danger">
              Uh-oh.. Failed to load installation details
              <div>{err.body?.title}</div>
            </div>
          </div>
        </div>
      ),
      success: ({ context, installation }) => {
        const common = context.commons.find((c) => c.commonAssortmentId === installation.commonId)
        const installationAssortment = context.installations.find((i) => i.installationId === id)
        const fileTypes = common ? fileTypesForCommon(common) : []

        return (
          <div>
            <div className="py-md px-lg flex items-center space-x-md">
              <Link aria-label="Home" to={'/'}>
                <HomeIcon size="1.5rem" />
              </Link>
              <ArrowRightIcon size="1rem" />
              <Link to="/installations">
                <h2>Installations</h2>
              </Link>
              <ArrowRightIcon size="1rem" />
              <h2>{installation.installationReference.serialNumber}</h2>
            </div>
            <div className="card">
              <Container>
                <InstallationHeader
                  installation={installation}
                  refresh={refresh}
                  history={installation.history}
                  assortmentStatus={installationAssortment?.assortmentStatus}
                  commonFileTypes={fileTypes}
                  commonIsLocked={!!common && common.lockout}
                />
                {installation.commonId && (
                  <InstallationAssortmentDatabase
                    commonId={installation.commonId}
                    installationId={installation.installationReference.installationId}
                    history={installation.history}
                    serialNumber={installation.installationReference.serialNumber}
                  />
                )}
              </Container>
            </div>
          </div>
        )
      },
    },
    combinedRemoteData
  )
}
