import React, { useContext, useState } from 'react'
import { Dialog } from '../../components/Dialog'
import { Link } from 'react-router-dom'
import { AssortmentStatus, CommonConfig } from 'domain/DTO'
import { CommonAssortmentStatusCell } from 'components/CommonAssortmentStatusCell'
import {
  allUiStatuses,
  fileTypesForCommon,
  getFileStatuses,
  getStatusEmoji,
  getStatusText,
  UiStatus,
} from 'domain/FileStatus'
import { CommonsAndInstallationsContext } from 'domain/CommonsAndInstallationsContext'
import { DuplicateCommonAssortmentDialog } from 'components/DuplicateCommonAssortmentDialog'
import { CommonAssortmentFields } from 'components/CommonAssortmentDialog'
import { deleteCommonAssortment, duplicateCommonAssortment } from 'services/CommonAssortmentService'
import { ConfirmDeletionDialog } from 'components/ConfirmDeletionDialog'
import { SortableHeaderContent, useTableSorting } from 'components/assortment/TableColumnSort'
import { padNumberToString } from 'lib/StringUtilities'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { ConflictSummary } from '../../components/ConflictSummary'
import { SRD } from 'srd'
import { PagedTable } from '@tomra/react-table'
import { CopyIcon, DeleteIcon } from '../../icons'
import SearchIcon from '../../icons/SearchIcon'

export const CommonAssortmentOverview = () => {
  const { data: contextRemoteData, refresh: refreshContext } = useContext(CommonsAndInstallationsContext)
  const [duplicateTarget, setDuplicateTarget] = useState<CommonConfig | undefined>()
  const [deletionCandidate, setDeleteCandidate] = useState<[string, string] | undefined>()
  const { selectedCountry } = useContext(SelectedCountryContext)

  const sorting = useTableSorting<CommonConfig>(
    {
      Name: (cc) => cc.name,
      '# Machines': (cc) => {
        if (SRD.isSuccess(contextRemoteData)) {
          const length = contextRemoteData.data.installations.filter(
            (ia) => ia.assortmentStatus?.commonId === cc.commonAssortmentId
          ).length
          return padNumberToString(length, '00000')
        }
        return '00000'
      },
    },
    'Name'
  )

  return SRD.match(
    {
      notAsked: () => <div className="loadingSpinner centerAbsolute" />,
      loading: () => <div className="loadingSpinner centerAbsolute" />,
      failure: (err) => (
        <div className="centerAbsolute">
          <div className="alert alert-danger">
            Uh-oh.. Failed to load Common Assortments
            <div>{err.body?.title}</div>
          </div>
        </div>
      ),
      success: (value) => {
        const dataSelection = value.commons.filter((common) => common.country === selectedCountry)
        return (
          <React.Fragment>
            {!value.commonAssortmentConflicts?.length ? null : (
              <ConflictSummary scopeConflicts={value.commonAssortmentConflicts} />
            )}
            <div className="card">
              <div className="p-md">
                <Link to="/common-assortment/new" className="btn btn-primary-dark inline py-sm">
                  Create Common Assortment
                </Link>
              </div>
              <div className="float-right mr-xs mb-sm">
                {allUiStatuses.map((uiStatus) => {
                  return <span className="mr-md">{getStatusEmoji(uiStatus) + ' ' + getStatusText(uiStatus)}</span>
                })}
              </div>
              <PagedTable
                data={sorting.sort(dataSelection)}
                columnHeaders={[
                  <div style={{ minWidth: '16em' }}>
                    <SortableHeaderContent name="Name" currentSort={sorting.currentSort} />
                  </div>,
                  <div style={{ minWidth: '3em' }} />,
                  <div style={{ minWidth: '16em' }}>Maintenance Window</div>,
                  <div style={{ minWidth: '10em' }}>
                    <SortableHeaderContent name="# Machines" currentSort={sorting.currentSort} />
                  </div>,
                  'Shape Status',
                  'Barcode Status',
                  'Crate Status',
                  'Local Crate Status',
                  'Actions',
                ]}
                rowFactory={(commonConfig) => {
                  const assortmentStatusForInstallations: AssortmentStatus[] = value.installations
                    .filter((ia) => ia.assortmentStatus?.commonId === commonConfig.commonAssortmentId)
                    .map((i) => i.assortmentStatus!!)
                  const fileTypes = fileTypesForCommon(commonConfig)
                  const typeAndStatusCounts = Object.entries(
                    countForTypeAndUiStatus(assortmentStatusForInstallations, fileTypes)
                  )
                  const link = `/common-assortment/${commonConfig.commonAssortmentId}`
                  return (
                    <tr key={commonConfig.commonAssortmentId}>
                      <td>
                        <Link to={link} className="link">
                          {commonConfig.name}
                        </Link>
                      </td>
                      <td>
                        {commonConfig.lockout ? (
                          <span title="Common is locked, will not create new machine updates">{'🔒'}</span>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {`${commonConfig.maintenanceWindow.start} - ${commonConfig.maintenanceWindow.end} (${commonConfig.maintenanceWindow.tz})`}
                      </td>
                      <td>
                        <Link to={'/installations?commonId=' + commonConfig.commonAssortmentId} className="inline-flex">
                          <a href="/" className="mr-sm link">
                            {assortmentStatusForInstallations.length}
                          </a>
                          <SearchIcon color="none" size="18" />
                        </Link>
                      </td>
                      <td>
                        {fileTypes.includes('SHAPE') ? (
                          <CommonAssortmentStatusCell
                            statusCounts={statusCountsForType(
                              'SHAPE',
                              commonConfig.commonAssortmentId,
                              typeAndStatusCounts
                            )}
                          />
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {fileTypes.includes('BARCODE') ? (
                          <CommonAssortmentStatusCell
                            statusCounts={statusCountsForType(
                              'BARCODE',
                              commonConfig.commonAssortmentId,
                              typeAndStatusCounts
                            )}
                          />
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {fileTypes.includes('CRATE') ? (
                          <CommonAssortmentStatusCell
                            statusCounts={statusCountsForType(
                              'CRATE',
                              commonConfig.commonAssortmentId,
                              typeAndStatusCounts
                            )}
                          />
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {fileTypes.includes('LCRATE') ? (
                          <CommonAssortmentStatusCell
                            statusCounts={statusCountsForType(
                              'LCRATE',
                              commonConfig.commonAssortmentId,
                              typeAndStatusCounts
                            )}
                          />
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-icon inline-block mx-sm"
                          title="Duplicate"
                          onClick={() => setDuplicateTarget(commonConfig)}
                        >
                          <CopyIcon />
                        </button>
                        <button
                          className="btn btn-icon inline-block mx-sm"
                          title="Remove"
                          onClick={() => setDeleteCandidate([commonConfig.name, commonConfig.commonAssortmentId])}
                        >
                          <DeleteIcon />
                        </button>
                      </td>
                    </tr>
                  )
                }}
                initialRecordsPerPage={100}
                recordsPerPageOptions={[10, 20, 50, 100]}
              />
              {duplicateTarget ? (
                <Dialog onClose={() => setDuplicateTarget(undefined)} shouldCloseOnOverlayClick={false}>
                  <DuplicateCommonAssortmentDialog
                    duplicatingFrom={duplicateTarget.name}
                    onComplete={async ({ name, installationScopes }) => {
                      const fields: CommonAssortmentFields = {
                        id: undefined,
                        name,
                        installationScopes,
                        scopeFilters: duplicateTarget.scopeFilters,
                        country: selectedCountry,
                        maintenanceWindow: duplicateTarget.maintenanceWindow,
                        barcodeStreamId: duplicateTarget.barcodeFileStream?.id,
                        crateStreamId: duplicateTarget.crateFileStream?.id,
                        shapeStreamId: duplicateTarget.shapeFileStream?.id,
                        concurrencyLimit: duplicateTarget.concurrencyLimit ?? undefined,
                        lockout: duplicateTarget.lockout,
                      }
                      await duplicateCommonAssortment(fields, duplicateTarget.commonAssortmentId)
                      setDuplicateTarget(undefined)
                    }}
                  />
                </Dialog>
              ) : null}
              {deletionCandidate ? (
                <Dialog onClose={() => setDeleteCandidate(undefined)} shouldCloseOnOverlayClick={false}>
                  <ConfirmDeletionDialog
                    entityName={deletionCandidate[0]}
                    onConfirm={async () => {
                      await deleteCommonAssortment(deletionCandidate[1])
                      setDeleteCandidate(undefined)
                      refreshContext()
                    }}
                    onClose={async () => setDeleteCandidate(undefined)}
                  />
                </Dialog>
              ) : null}
            </div>
          </React.Fragment>
        )
      },
    },
    contextRemoteData
  )
}

function countForTypeAndUiStatus(assortmentStatuses: AssortmentStatus[], commonFileTypes: string[]) {
  const map: Record<string, number> = {}
  assortmentStatuses.forEach((assortmentStatus) => {
    getFileStatuses(assortmentStatus, commonFileTypes).forEach((fileStatus) => {
      map[fileStatus.type + ':' + fileStatus.uiStatus] = (map[fileStatus.type + ':' + fileStatus.uiStatus] ?? 0) + 1
    })
  })
  return map
}

function statusCountsForType(
  type: string,
  commonId: string,
  typeAndStatusCounts: [string, number][]
): [UiStatus, number, string][] {
  return typeAndStatusCounts
    .filter(([key]) => key.startsWith(type))
    .map(([key, value]) => {
      const uiStatus = key.split(':')[1] as UiStatus
      return [uiStatus, value, `/installations?commonId=${commonId}&fileStatus=${uiStatus}`]
    })
}
