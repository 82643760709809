import React from 'react'
import { EditIcon } from '../icons'

type Props = {
  title: string
  onClick: () => void
}

export const TitleWithEdit = ({ title, onClick }: Props) => {
  return (
    <div className="inline-flex mt-md">
      <label>{title}</label>
      <button className="ml-sm" data-testid="show-edit-dialog" onClick={onClick}>
        <EditIcon />
      </button>
    </div>
  )
}
