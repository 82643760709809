import React from 'react'

type RefreshIconProps = {
  color?: string
  size?: string
}

export const RefreshIcon = (props: RefreshIconProps) => (
  <svg
    fill={props.color || 'none'}
    height={props.size || '24'}
    viewBox="0 0 24 24"
    width={props.size || '24'}
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5px"
    stroke="#000000"
  >
    <polyline className="a" points="5.25 14.248 5.25 18.748 0.75 18.748" />
    <polyline className="a" points="18.75 9.748 18.75 5.248 23.25 5.248" />
    <path className="a" d="M19.032,5.245A9.752,9.752,0,0,1,8.246,21" />
    <path className="a" d="M4.967,18.751A9.753,9.753,0,0,1,15.754,3" />
  </svg>
)
