import React, { useState } from 'react'
import { FormLayout } from 'styles/FormLayout'
import { InnerContainer } from 'styles/PageLayout'
import {
  UNSET,
  ActionNames,
  SonderpfandAction,
  SonderpfandActionType,
  SonderpfandRejectActionType,
  SonderpfandTransportEndActionType,
} from 'domain/Assortment'
import { SonderpfandGroup } from 'domain/DTO'
import styled from 'styled-components'
import { colors } from 'styles'

const Warning = styled.span`
  color: ${colors.RED};
`

export type SonderpfandActionSnapshot = {
  action?: SonderpfandAction
}

type Props = {
  id: string
  group: SonderpfandGroup
  numberOfRowsWithReject: number
  snapshot: SonderpfandActionSnapshot
  onApply: (sonderpfandGroupId: string, snapshot: SonderpfandActionSnapshot) => void
}

export const SonderpfandActionDialog = ({ group, numberOfRowsWithReject, snapshot, onApply }: Props) => {
  const [action, setAction] = useState(snapshot.action)

  return (
    <div className="card">
      <FormLayout.Content>
        <h3>Sonderpfand group action</h3>
        <InnerContainer>
          <select
            className="select"
            onChange={(e) => setAction({ ...action, type: e.target.value as SonderpfandActionType })}
            value={action?.type}
          >
            <option value={UNSET} />
            <option value={SonderpfandRejectActionType.SET}>{ActionNames[SonderpfandRejectActionType.SET]}</option>
            <option value={SonderpfandTransportEndActionType.SET}>
              {ActionNames[SonderpfandTransportEndActionType.SET]}
            </option>
          </select>
        </InnerContainer>
        <InnerContainer>
          {hasChanged(snapshot.action, action) && numberOfRowsWithReject > 0 && (
            <Warning>{`Warning: Applying this Sonderpfand action will remove ${numberOfRowsWithReject} existing reject actions already specified.`}</Warning>
          )}
        </InnerContainer>
        <FormLayout.FooterContainer>
          <FormLayout.Footer>
            <button
              className="btn btn-primary-dark py-sm"
              onClick={() => {
                onApply(group.id, { action: action })
              }}
            >
              <span>Save</span>
            </button>
          </FormLayout.Footer>
        </FormLayout.FooterContainer>
      </FormLayout.Content>
    </div>
  )
}

function hasChanged(original?: SonderpfandAction, changed?: SonderpfandAction) {
  return original?.type !== changed?.type
}
