import React from 'react'
import { getFileStatuses, getStatusEmoji, FileStatus } from 'domain/FileStatus'
import styled from 'styled-components'
import { AssortmentStatus } from 'domain/DTO'

type Props = {
  assortmentStatus: AssortmentStatus
  commonFileTypes: string[]
}

const FileStatusContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const FileStatusItem = styled.div`
  flex: 0 1 90px;
`

export const AssortmentStatusCell = ({ assortmentStatus, commonFileTypes }: Props) => {
  const fileStatuses = getFileStatuses(assortmentStatus, commonFileTypes)
  return <FileStatusContainer>{fileStatuses.map(renderFileStatus)}</FileStatusContainer>
}

function renderFileStatus(fileStatus: FileStatus) {
  const icon = getStatusEmoji(fileStatus.uiStatus)
  return <FileStatusItem key={fileStatus.type} title={fileStatus.cause}>{`${icon} ${fileStatus.type}`}</FileStatusItem>
}
