import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AssortmentStatusCell } from 'components/AssortmentStatusCell'
import { allUiStatuses, fileTypesForCommon, getFileStatuses, getStatusEmoji, getStatusText } from 'domain/FileStatus'
import { CommonsAndInstallationsContext } from 'domain/CommonsAndInstallationsContext'
import {
  parseLocationSearchAsQueryParameters,
  toQueryParamUrlString,
  updateQueryParamValue,
} from 'lib/QueryParamUtility'
import { useAutoRefresh } from 'lib/RemoteData'
import { SortableHeader, useTableSorting } from 'components/assortment/TableColumnSort'
import { InstallationAssortment } from 'domain/DTO'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { SRD } from 'srd'
import { getPageData, PagedTableControls } from '@tomra/react-table'

export const InstallationsOverview = () => {
  const { data: contextRemoteData, refresh } = useContext(CommonsAndInstallationsContext)
  useAutoRefresh(refresh, 60000)

  const [textSearch, setValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(100)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParameters = parseLocationSearchAsQueryParameters(location.search)
  const { selectedCountry } = useContext(SelectedCountryContext)
  const commonIdFilter = queryParameters.find(([key, _]) => key === 'commonId')?.[1] ?? ''
  const fileStatusFilter = queryParameters.find(([key, _]) => key === 'fileStatus')?.[1] ?? ''
  const replaceQueryParamAndPushToHistory = (key: string, newValue: string) => {
    setCurrentPage(1)
    const urlToPush = location.pathname + toQueryParamUrlString(updateQueryParamValue(queryParameters, key, newValue))
    navigate(urlToPush)
  }
  const sorting = useTableSorting<InstallationAssortment>(
    {
      Serial: (ia) => ia.serialNumber,
      Common: (ia) => {
        if (SRD.isSuccess(contextRemoteData)) {
          return (
            contextRemoteData.data.commons.find((c) => c.commonAssortmentId === ia.assortmentStatus?.commonId)?.name ??
            ''
          )
        }
        return ''
      },
      'Chain / Sub Chain': (ia) => (ia.chainName ?? ia.subChainName ?? '').trim(),
      'Location (Country Code, Region)': (ia) =>
        `${ia.locationName} [${ia.country}${ia.region ? ', ' + ia.region : ''}]`.trim(),
    },
    'Serial'
  )

  return SRD.match(
    {
      notAsked: () => <div>Empty</div>,
      loading: () => (
        <div className="card">
          <div className="loadingSpinner centerAbsolute" />
        </div>
      ),
      failure: (err) => (
        <div className="card">
          <div className="centerAbsolute">
            <div className="alert alert-danger">
              Uh-oh.. Failed to load Installations
              <div>{err.body?.title}</div>
            </div>
          </div>
        </div>
      ),
      success: (value) => {
        const installationsForSelectedCountry = value.installations.filter((i) => i.country === selectedCountry)
        const dataSelection = installationsForSelectedCountry.filter((installationAssortment) => {
          const { serialNumber, chainName, subChainName, locationName, country, region } = installationAssortment
          const concatedTerms = `${serialNumber}:${chainName ?? ''}:${subChainName ?? ''}:${locationName}:${country}:${
            region ?? ''
          }`
          if (installationAssortment.assortmentStatus !== null) {
            const status = installationAssortment.assortmentStatus
            const common = value.commons.find((c) => c.commonAssortmentId === status.commonId)
            const isInCommonFilter = commonIdFilter === '' || status.commonId === commonIdFilter
            const isInFileStatusFilter =
              fileStatusFilter === '' ||
              getFileStatuses(status, fileTypesForCommon(common!!)).some((s) => s.uiStatus === fileStatusFilter)
            return (
              concatedTerms.toLowerCase().includes(textSearch.toLowerCase()) && isInCommonFilter && isInFileStatusFilter
            )
          }
          const commonFilterIsDefined = commonIdFilter !== '' && commonIdFilter !== 'NotAssigned'
          if (fileStatusFilter !== '' || commonFilterIsDefined) {
            return false
          }
          return concatedTerms.toLowerCase().includes(textSearch.toLowerCase())
        })

        const filteredData = sorting.sort(dataSelection)
        const csvHeader = 'Serial;Chain;Subchain;Location\n'
        const filteredDataCsv =
          csvHeader +
          filteredData.map((i) => `${i.serialNumber};${i.chainName};${i.subChainName};${i.locationName}`).join('\n')
        return (
          <div className="card">
            <div className="flex justify-between p-md">
              <div className="inline-flex">
                <input
                  type="text"
                  className="textfield w-auto"
                  data-testid="search-installation"
                  onChange={(e) => {
                    setValue(e.target.value)
                    setCurrentPage(1)
                  }}
                />
                <select
                  className="select w-auto ml-md"
                  onChange={(e) => replaceQueryParamAndPushToHistory('commonId', e.target.value)}
                  value={commonIdFilter}
                >
                  <option key={0} value="">
                    Select Common
                  </option>
                  <option key={1} value="NotAssigned">{`Not Assigned [${
                    installationsForSelectedCountry.filter((i) => !i.assortmentStatus).length
                  }]`}</option>
                  {value.commons
                    .filter((common) => common.country === selectedCountry)
                    .map((common) => {
                      const length = installationsForSelectedCountry.filter(
                        (ia) => ia.assortmentStatus?.commonId === common.commonAssortmentId
                      ).length
                      return (
                        <option key={common.commonAssortmentId} value={common.commonAssortmentId}>
                          {common.name + ' [' + length + ']'}
                        </option>
                      )
                    })}
                </select>
              </div>
              <div className="inline-flex float-right">
                <select
                  className="select w-auto"
                  onChange={(e) => replaceQueryParamAndPushToHistory('fileStatus', e.target.value)}
                  value={fileStatusFilter}
                >
                  <option key={0} value="">
                    All Assortment Statuses
                  </option>
                  {allUiStatuses.map((uiStatus) => {
                    return (
                      <option key={uiStatus} value={uiStatus}>
                        {getStatusEmoji(uiStatus) + ' ' + getStatusText(uiStatus)}
                      </option>
                    )
                  })}
                </select>
                <a
                  className="btn btn-primary-dark ml-md py-sm"
                  download="installations.csv"
                  href={'data:text/csv;charset=utf-8,' + encodeURI(filteredDataCsv)}
                >
                  Export
                </a>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <SortableHeader name="Serial" currentSort={sorting.currentSort} minWidth="8em" />
                  <SortableHeader name="Common" currentSort={sorting.currentSort} minWidth="14em" />
                  <th style={{ minWidth: '16em' }}>Assortment Status</th>
                  <SortableHeader name="Chain / Sub Chain" currentSort={sorting.currentSort} minWidth="10em" />
                  <SortableHeader
                    name="Location (Country Code, Region)"
                    currentSort={sorting.currentSort}
                    minWidth="40em"
                  />
                </tr>
              </thead>
              <tbody>
                {getPageData(filteredData, currentPage, recordsPerPage).map((installationAssortment) => {
                  const { installationId, serialNumber, chainName, subChainName, locationName, country, region } =
                    installationAssortment
                  const assortmentStatus = installationAssortment.assortmentStatus
                  const common = value.commons.find((c) => c.commonAssortmentId === assortmentStatus?.commonId)
                  const fileTypes = common ? fileTypesForCommon(common) : []
                  return (
                    <tr key={installationId}>
                      <td>
                        <Link to={`/installation/${installationId}`} className="link">
                          {serialNumber}
                        </Link>
                      </td>
                      <td>
                        {common ? (
                          <Link to={`/common-assortment/${common.commonAssortmentId}`} className="link">
                            {common.name}
                          </Link>
                        ) : (
                          'Not Assigned'
                        )}
                      </td>
                      <td>
                        {assortmentStatus ? (
                          <AssortmentStatusCell assortmentStatus={assortmentStatus} commonFileTypes={fileTypes} />
                        ) : (
                          'N/A'
                        )}
                      </td>
                      <td>{chainName ?? subChainName}</td>
                      <td>{`${locationName} [${country}${region ? ', ' + region : ''}]`}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <PagedTableControls
              currentPage={currentPage}
              numOfItems={filteredData.length}
              recordsPerPage={recordsPerPage}
              onPageUpdate={setCurrentPage}
              recordsPerPageOptions={[10, 20, 50, 100]}
              onRecordsPerPageChange={(recordsPerPage: number) => {
                setRecordsPerPage(recordsPerPage)
                setCurrentPage(1)
              }}
            />
          </div>
        )
      },
    },
    contextRemoteData
  )
}
