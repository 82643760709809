import React from 'react'
import { Container } from 'styles/PageLayout'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useRemoteData } from 'lib/RemoteData'
import { API_HOST } from 'lib'
import { CommonConfig } from 'domain/DTO'
import { CommonAssortmentDatabase } from 'components/assortment/AssortmentDatabase'
import { SRD } from 'srd'
import { ArrowRightIcon, EditIcon, SearchIcon, HomeIcon } from '../../icons'

const KeyValueOverview = styled.div`
  display: flex;
  flex-direction: column;
`

const KeyValueItem = styled.div`
  display: flex;
`

const Key = styled.div`
  flex: 1 0 15rem;
  min-height: 1.5rem;
  font-weight: 'bold';
`

const Value = styled.div`
  flex: 1 0 15rem;
  font-weight: 'bold';
`

type HeaderProps = {
  common: CommonConfig
}

const AssortmentHeader = ({ common }: HeaderProps) => {
  return (
    <KeyValueOverview>
      <KeyValueItem>
        <Key>Maintenance Window:</Key>
        <Value>
          <span>{`${common.maintenanceWindow.start} - ${common.maintenanceWindow.end} (${common.maintenanceWindow.tz})`}</span>
        </Value>
      </KeyValueItem>
      <KeyValueItem>
        <Key>Max Concurrent Uploads:</Key>
        <Value>{common.concurrencyLimit ?? 'Unlimited'}</Value>
      </KeyValueItem>
      <KeyValueItem>
        <Key>Shape File:</Key>
        <Value>
          {common.shapeFileStream ? (
            <Link to={`/file-stream/${common.shapeFileStream.id}`}>{common.shapeFileStream.name}</Link>
          ) : (
            <span>No Shape File</span>
          )}
        </Value>
      </KeyValueItem>
      <KeyValueItem>
        <Key>Barcode File:</Key>
        <Value>
          {common.barcodeFileStream ? (
            <Link to={`/file-stream/${common.barcodeFileStream.id}`}>{common.barcodeFileStream.name}</Link>
          ) : (
            <span>No Barcode File</span>
          )}
        </Value>
      </KeyValueItem>
      <KeyValueItem>
        <Key>Crate File:</Key>
        <Value>
          {common.crateFileStream ? (
            <Link to={`/file-stream/${common.crateFileStream.id}`}>{common.crateFileStream.name}</Link>
          ) : (
            <span>No Crate File</span>
          )}
        </Value>
      </KeyValueItem>
      <KeyValueItem>
        <Key>Installations:</Key>
        <Value>
          <Link to={'/installations?commonId=' + common.commonAssortmentId}>
            <SearchIcon size="18" />
          </Link>
        </Value>
      </KeyValueItem>
    </KeyValueOverview>
  )
}

function LoadingSpinner() {
  return (
    <div className="card">
      <div data-testid="loading">
        <div className="loadingSpinner centerAbsolute" />
      </div>
    </div>
  )
}

export const CommonAssortment = () => {
  const { id } = useParams<{ id: string }>()
  if (!id) {
    throw new Error('Missing parameter "id"')
  }
  const { data: commonRemoteData } = useRemoteData<CommonConfig>(`${API_HOST}/v1/common/${id}`)

  return SRD.match(
    {
      notAsked: () => <LoadingSpinner />,
      loading: () => <LoadingSpinner />,
      failure: (err) => (
        <div className="card">
          <div className="centerAbsolute">
            <div className="alert alert-danger">
              Uh-oh.. Failed to load Common Assortment Details
              <div>{err.body?.title}</div>
            </div>
          </div>
        </div>
      ),
      success: (data) => (
        <div>
          <div className="py-md px-lg flex items-center space-x-md">
            <Link aria-label="Home" to={'/'}>
              <HomeIcon size="1.5rem" />
            </Link>
            <ArrowRightIcon size="1rem" />
            <Link to="/common-assortment">
              <h2>Common</h2>
            </Link>
            <ArrowRightIcon size="1rem" />
            <h2>{data.name}</h2>
          </div>
          <div className="card">
            <Container>
              <div className="my-md inline-flex">
                <h2>{(data.lockout ? '🔒 ' : '') + data.name}</h2>
                <Link
                  className="ml-sm"
                  to={'/common-assortment/' + data.commonAssortmentId + '/edit'}
                  role="button"
                  data-testid="show-edit-dialog"
                >
                  <EditIcon />
                </Link>
              </div>
              <AssortmentHeader common={data} />
              <CommonAssortmentDatabase common={data} />
            </Container>
          </div>
        </div>
      ),
    },
    commonRemoteData
  )
}
