import { CommonAssortmentFields } from 'components/CommonAssortmentDialog'
import { API_HOST } from 'lib'
import { authenticatedFetch, extractErrorMessage } from 'lib/authenticatedGet'
import { CommonsAndInstallationsContextData, MaintenanceWindow } from '../domain/DTO'
import { fromInstallationScope, PathScope, StructureNode } from '../lib/InstallationScope'
import { ClaimedScopesMap } from '../components/masterdata-assignment/MasterdataAssignment'

const toMaintenanceWindowRaw = (mw: MaintenanceWindow): string => `${mw.start};${mw.end};${mw.tz};${mw.weekdays ?? 127}`

export const duplicateCommonAssortment = async (fields: CommonAssortmentFields, duplicatedFromId: string) => {
  return authenticatedFetch(`${API_HOST}/v1/common/${duplicatedFromId}/duplicate`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        ...fields,
        maintenanceWindow: `${fields.maintenanceWindow.start};${fields.maintenanceWindow.end};${fields.maintenanceWindow.tz}`,
      },
    }),
  }).catch(extractErrorMessage)
}

export const createCommonAssortment = async (fields: CommonAssortmentFields) => {
  return authenticatedFetch(`${API_HOST}/v1/common`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        ...fields,
        maintenanceWindow: toMaintenanceWindowRaw(fields.maintenanceWindow),
      },
    }),
  }).catch(extractErrorMessage)
}

export const updateCommonAssortment = (id: string, fields: CommonAssortmentFields) => {
  return authenticatedFetch(`${API_HOST}/v1/common/${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      data: {
        ...fields,
        maintenanceWindow: toMaintenanceWindowRaw(fields.maintenanceWindow),
      },
    }),
  }).catch(extractErrorMessage)
}

export const deleteCommonAssortment = async (commonId: string) => {
  return authenticatedFetch(`${API_HOST}/v1/common/${commonId}`, {
    method: 'DELETE',
  }).catch(extractErrorMessage)
}

export function getClaimedScopesMap(
  commonsAndInstallations: CommonsAndInstallationsContextData,
  hierarchyData: StructureNode[],
  selectedCountry: string,
  initialScopes?: PathScope[]
): ClaimedScopesMap {
  const claimedScopes = commonsAndInstallations.commons
    .filter((c) => c.country === selectedCountry)
    .map((c) => [c.name, fromInstallationScope(c.installationScopes, hierarchyData)] as [string, PathScope[]])
    .filter(([_, allow]) => !!allow)
  return toClaimedScopesMapWithoutInitial(claimedScopes, initialScopes ?? [])
}

function toClaimedScopesMapWithoutInitial(scopes: [string, PathScope[]][], initial: PathScope[]): ClaimedScopesMap {
  let claimedScopesMap: ClaimedScopesMap = new Map()

  scopes.forEach(([commonName, pathScopes]) => {
    pathScopes.forEach(({ include, exclude }) => {
      include.forEach((s) => pushOrSet(claimedScopesMap, s, { name: commonName, included: true }))
      exclude.forEach((s) => pushOrSet(claimedScopesMap, s, { name: commonName, included: false }))
    })
  })

  initial.forEach(({ include, exclude }) => {
    include.forEach((includedPath) => {
      if ((claimedScopesMap.get(includedPath)?.length ?? 0) > 1) {
        claimedScopesMap.set(
          includedPath,
          claimedScopesMap.get(includedPath)?.filter((claimedCommon) => {
            return !claimedCommon.included
          })
        )
      } else {
        claimedScopesMap.delete(includedPath)
      }
    })
    exclude.forEach((excludedPath) => {
      if ((claimedScopesMap.get(excludedPath)?.length ?? 0) > 1) {
        claimedScopesMap.set(
          excludedPath,
          claimedScopesMap.get(excludedPath)?.filter((claimedCommon) => {
            return claimedCommon.included
          })
        )
      } else {
        claimedScopesMap.delete(excludedPath)
      }
    })
  })
  return claimedScopesMap
}

/**
 * Pushes a value to an existing key or creates a new entry if the key does not exist.
 */
function pushOrSet<K, V>(map: Map<K, Array<V> | undefined>, key: K, valueToAdd: V): void {
  if (map.get(key)) {
    map.get(key)?.push(valueToAdd)
  } else {
    map.set(key, [valueToAdd])
  }
}
