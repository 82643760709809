import React, { useContext, useState } from 'react'
import { Dialog } from '../../components/Dialog'
import { API_HOST } from 'lib/environment'
import { useAutoRefresh, useRemoteData } from 'lib/RemoteData'
import { Link } from 'react-router-dom'
import { FileStreamDialog } from 'components/FileStreamDialog'
import { createFileStream, deleteFileStream } from 'services/FileStreamService'
import { ConfirmDeletionDialog } from 'components/ConfirmDeletionDialog'
import { SortableHeaderContent, useTableSorting } from 'components/assortment/TableColumnSort'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { SRD } from 'srd'
import { PagedTable } from '@tomra/react-table'
import { DeleteIcon } from '../../icons'

export type CommonAssortmentCountDTO = {
  commonId: string
  name: string
  currentInstallations: number
}

export type FileStreamOverviewDTO = {
  streamId: string
  name: string
  country: string
  fileType: 'BARCODE' | 'SHAPE' | 'CRATE'
  commonAssortmentCounts: Array<CommonAssortmentCountDTO>
}

function LoadingSpinner() {
  return (
    <div className="card">
      <div data-testid="loading" className="loadingSpinner centerAbsolute" />
    </div>
  )
}

export const FileStreamOverview = () => {
  const { selectedCountry } = useContext(SelectedCountryContext)
  const { data: fileStreamRemoteData, refresh: refreshFileStream } = useRemoteData<FileStreamOverviewDTO[]>(
    `${API_HOST}/v1/filestream?country=` + selectedCountry
  )
  useAutoRefresh(refreshFileStream, 60000)

  const [deletionCandidate, setDeleteCandidate] = useState<[string, string] | undefined>()
  const [showCreateFileStreamDialog, setShowCreateFileStreamDialog] = useState(false)
  const [fileType, setFileType] = useState('SHAPE')
  const [activeTab, setActiveTab] = useState('SHAPE')

  const sorting = useTableSorting<FileStreamOverviewDTO>(
    {
      Name: (fs) => fs.name,
      '# Common Assortments': (fs) => {
        const count: string = fs.commonAssortmentCounts.length.toString()
        return ('00000' + count).substring(count.length)
      },
      '# Installations': (fs) => {
        const count: string = fs.commonAssortmentCounts
          .reduce((acc, ref) => acc + ref.currentInstallations, 0)
          .toString()
        return ('00000' + count).substring(count.length)
      },
    },
    'Name'
  )

  return SRD.match(
    {
      notAsked: () => <LoadingSpinner />,
      loading: () => <LoadingSpinner />,
      failure: (err) => (
        <div className="card">
          <div className="centerAbsolute">
            <div className="alert alert-danger">
              Uh-oh.. Failed to load File Stream Overview
              <div>{err.body?.title}</div>
            </div>
          </div>
        </div>
      ),
      success: (value) => {
        const availableInCountry = value.filter((fileStream) => fileStream.country === selectedCountry)
        const dataSelection = availableInCountry.filter((fileStream) => fileStream.fileType === fileType)
        return (
          <div className="card">
            <div className="p-md">
              <button className="btn btn-primary-dark py-sm" onClick={() => setShowCreateFileStreamDialog(true)}>
                Create File Stream
              </button>
            </div>
            <div>
              <div className="tabs w-6/12 mb-1">
                <button
                  className={`tab ${activeTab === 'SHAPE' ? 'active' : ''}`}
                  onClick={() => {
                    setFileType('SHAPE')
                    setActiveTab('SHAPE')
                  }}
                >
                  Shape
                </button>
                <button
                  className={`tab ${activeTab === 'BARCODE' ? 'active' : ''}`}
                  onClick={() => {
                    setFileType('BARCODE')
                    setActiveTab('BARCODE')
                  }}
                >
                  Barcode
                </button>
                <button
                  className={`tab ${activeTab === 'CRATE' ? 'active' : ''}`}
                  onClick={() => {
                    setFileType('CRATE')
                    setActiveTab('CRATE')
                  }}
                >
                  Crate
                </button>
              </div>
              <PagedTable
                data={sorting.sort(dataSelection)}
                columnHeaders={[
                  <div style={{ minWidth: '14em' }}>
                    <SortableHeaderContent name="Name" currentSort={sorting.currentSort} />
                  </div>,
                  <div style={{ minWidth: '14em' }}>
                    <SortableHeaderContent name="# Common Assortments" currentSort={sorting.currentSort} />
                  </div>,
                  <div style={{ minWidth: '14em' }}>
                    <SortableHeaderContent name="# Installations" currentSort={sorting.currentSort} />
                  </div>,
                  'Delete',
                ]}
                rowFactory={(fileStream) => {
                  const link = `/file-stream/${fileStream.streamId}`
                  return (
                    <tr key={fileStream.streamId}>
                      <td>
                        <Link to={link} className="link">
                          {fileStream.name}
                        </Link>
                      </td>
                      <td>{fileStream.commonAssortmentCounts.length}</td>
                      <td>
                        {fileStream.commonAssortmentCounts.reduce((acc, ref) => acc + ref.currentInstallations, 0)}
                      </td>
                      <td>
                        <button
                          className="btn btn-icon"
                          title="Delete"
                          onClick={() => setDeleteCandidate([fileStream.name, fileStream.streamId])}
                        >
                          <DeleteIcon />
                        </button>
                      </td>
                    </tr>
                  )
                }}
                initialRecordsPerPage={100}
                className="table"
              />
            </div>
            {showCreateFileStreamDialog ? (
              <Dialog onClose={() => setShowCreateFileStreamDialog(false)} shouldCloseOnOverlayClick={false}>
                <FileStreamDialog
                  onComplete={(name, type) =>
                    createFileStream(name, type, selectedCountry).then((_) => {
                      setShowCreateFileStreamDialog(false)
                      refreshFileStream()
                    })
                  }
                />
              </Dialog>
            ) : null}
            {deletionCandidate ? (
              <Dialog onClose={() => setDeleteCandidate(undefined)} shouldCloseOnOverlayClick={false}>
                <ConfirmDeletionDialog
                  entityName={deletionCandidate[0]}
                  onConfirm={async () => {
                    await deleteFileStream(deletionCandidate[1])
                    setDeleteCandidate(undefined)
                    refreshFileStream()
                  }}
                  onClose={async () => setDeleteCandidate(undefined)}
                />
              </Dialog>
            ) : null}
          </div>
        )
      },
    },
    fileStreamRemoteData
  )
}
