import React from 'react'
import styled from 'styled-components'
import { ListDiff, ValidationMessage } from 'domain/DTO'
import { getStatusEmoji } from 'domain/FileStatus'

const SummaryTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`

const SummaryRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

const SummaryLabel = styled.span`
  flex: 1 0 5rem;
`

const SummaryValue = styled.div`
  flex: 1 0 5rem;
  font-weight: bold;
`

type Props = {
  diff: ListDiff[]
  messages: ValidationMessage[]
}

export const ListDiffSummary = ({ diff, messages }: Props) => {
  const targetedWarnings = messages.filter((m) => m.severity === 'WARNING' && m.entityId !== null)
  const targetedErrors = messages.filter((m) => m.severity === 'ERROR' && m.entityId !== null)
  return (
    <SummaryTable>
      {targetedWarnings.length > 0 ? (
        <SummaryRow>
          <SummaryLabel>{`Warnings:`}</SummaryLabel>
          <SummaryValue data-testid="warnings">{`${targetedWarnings.length} ${getStatusEmoji(
            'WARNING'
          )}`}</SummaryValue>
        </SummaryRow>
      ) : null}
      {targetedErrors.length > 0 ? (
        <SummaryRow>
          <SummaryLabel>{`Errors:`}</SummaryLabel>
          <SummaryValue data-testid="errors">{`${targetedErrors.length} ${getStatusEmoji('ERROR')}`}</SummaryValue>
        </SummaryRow>
      ) : null}
      {isNoChanges(diff) && (
        <SummaryRow>
          <SummaryLabel>No changes detected</SummaryLabel>
        </SummaryRow>
      )}
      {hasAdded(diff) && (
        <SummaryRow>
          <SummaryLabel>Added:</SummaryLabel>
          <SummaryValue data-testid={'added'}>{getNumberOfAdded(diff)}</SummaryValue>
        </SummaryRow>
      )}
      {hasRemoved(diff) && (
        <SummaryRow>
          <SummaryLabel>Removed:</SummaryLabel>
          <SummaryValue data-testid={'removed'}>{getNumberOfRemoved(diff)}</SummaryValue>
        </SummaryRow>
      )}
      {hasUpdated(diff) && (
        <SummaryRow>
          <SummaryLabel>Updated:</SummaryLabel>
          <SummaryValue data-testid={'updated'}>{getNumberOfUpdated(diff)}</SummaryValue>
        </SummaryRow>
      )}
    </SummaryTable>
  )
}

function isNoChanges(diff: ListDiff[]): boolean {
  return !hasAdded(diff) && !hasRemoved(diff) && !hasUpdated(diff)
}

function hasAdded(diff: ListDiff[]): boolean {
  return getNumberOfAdded(diff) > 0
}

function hasRemoved(diff: ListDiff[]): boolean {
  return getNumberOfRemoved(diff) > 0
}

function hasUpdated(diff: ListDiff[]): boolean {
  return getNumberOfUpdated(diff) > 0
}

function getNumberOfAdded(diff: ListDiff[]) {
  return diff.map((v) => v.added.length).reduce((sum, current) => sum + current)
}

function getNumberOfRemoved(diff: ListDiff[]) {
  return diff.map((v) => v.removed.length).reduce((sum, current) => sum + current)
}

function getNumberOfUpdated(diff: ListDiff[]) {
  return diff.map((v) => v.updated.length).reduce((sum, current) => sum + current)
}
