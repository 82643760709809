import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { TreeViewer } from './TreeBrowser'
import { ScopeSummary } from './ScopeSummary'
import {
  PathScope,
  ScopeFilterConfiguration,
  ScopeFilterWithStatus,
  searchInNodes,
  StructureNode,
} from 'lib/InstallationScope'
import { FilterSummary } from './FilterSummary'
import { RefreshIcon } from '../../icons'

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
`

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 1100px;
  min-height: 600px;

  > * {
    flex: 1 100%;
  }
`

type Props = {
  hierarchyData: StructureNode[]
  claimedScopesMap: ClaimedScopesMap
  onComplete: (scopes: PathScope[], filters: ScopeFilterWithStatus[]) => void
  initialScopes?: PathScope[]
  initialFilters?: ScopeFilterWithStatus[]
}

export type ClaimedCommon = {
  name: string
  included: boolean
}

export type ClaimedScopesMap = Map<string, ClaimedCommon[] | undefined>

export const MasterdataAssignment = ({
  onComplete,
  claimedScopesMap,
  hierarchyData,
  initialScopes,
  initialFilters,
}: Props) => {
  const [pathScopes, setPathScopes] = useState<PathScope[]>(initialScopes ?? [])
  const [filterConfiguration, setFilterConfiguration] = useState<ScopeFilterConfiguration>(
    new ScopeFilterConfiguration(initialFilters ?? [])
  )
  const [match, setMatch] = useState([])
  const [searchValue, setSearchValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const findFilteredDenies = (path: string) => {
    const deniedDueToAllocation: string[] = Array.from(claimedScopesMap.keys()).filter(
      (claimedScope) => claimedScope.startsWith(path) && claimedScopesMap.get(claimedScope)
    )
    return filterConfiguration.isScopeFiltersEnabled() ? [] : [...deniedDueToAllocation].filter((deny) => deny !== path)
  }

  const include = (path: string) => {
    const scopeWhereExcluded = pathScopes.find((scope) => scope.exclude.some((excluded) => excluded === path))
    const existingChild = pathScopes.find((scope) => scope.include.some((included) => included.startsWith(path)))
    const withoutChildScopes = pathScopes.filter(
      (scope) =>
        !scope.include.some((included) => included.startsWith(path)) &&
        !scope.exclude.some((excluded) => excluded === path)
    )

    setPathScopes([
      ...withoutChildScopes,
      scopeWhereExcluded
        ? {
            include: scopeWhereExcluded.include,
            exclude: scopeWhereExcluded.exclude.filter((excluded) => excluded !== path),
          }
        : {
            include: [path],
            exclude: [...findFilteredDenies(path), ...(existingChild?.exclude ?? [])],
          },
    ])
  }

  const exclude = (path: string) => {
    const parentScope = pathScopes.find((scope) =>
      scope.include.some((included) => path !== included && path.startsWith(included))
    )
    const withoutRelevantOrRemovedScope = pathScopes.filter(
      (scope) => !scope.include.some((included) => path.startsWith(included))
    )
    setPathScopes([
      ...withoutRelevantOrRemovedScope,
      ...(parentScope ? [{ include: parentScope.include, exclude: [...parentScope.exclude, path] }] : []),
    ])
  }

  const applyFilter = (updatedFilter: ScopeFilterWithStatus) => {
    const index = filterConfiguration.scopeFilters.findIndex(
      (filter) => updatedFilter.type === filter.type && updatedFilter.value === filter.value
    )
    filterConfiguration.scopeFilters = [
      ...filterConfiguration.scopeFilters.slice(0, index),
      updatedFilter,
      ...filterConfiguration.scopeFilters.slice(index + 1),
    ]

    setFilterConfiguration(new ScopeFilterConfiguration(filterConfiguration.scopeFilters))

    if (!filterConfiguration.isScopeFiltersEnabled()) {
      setPathScopes(
        pathScopes.flatMap((pathScope) =>
          pathScope.include.map((includePath) => {
            return {
              include: pathScope.include,
              exclude: [...new Set(pathScope.exclude.concat([...findFilteredDenies(includePath)]))],
            }
          })
        )
      )
    }
  }

  const clearValues = () => {
    setMatch([])
    setSearchValue('')
  }

  return (
    <RowContainer>
      <Heading>
        <div className="w-full grid grid-cols-2">
          <div className="justify-center inline-flex w-3/4 ml-md">
            <form
              className="flex m-md"
              onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (inputRef.current?.value) {
                  const matchedNodes = searchInNodes(inputRef.current?.value, hierarchyData, '', [])
                  // @ts-ignore
                  setMatch(matchedNodes)
                  setSearchValue(inputRef.current?.value)
                }
              }}
            >
              <input className="textfield w-1/2" ref={inputRef} type="text" />
              <button className="btn w-1/2 ml-sm">Search</button>
              <button type="reset" className="ml-sm" onClick={clearValues}>
                <RefreshIcon size="30" />
              </button>
            </form>
          </div>
          <div className="mt-sm">
            <h3 className="mt-md float-left">Define Scope For Common Assortment</h3>
            <button
              className="btn btn-primary-dark float-right py-sm mr-lg"
              onClick={() => onComplete(pathScopes, filterConfiguration.scopeFilters)}
            >
              Save
            </button>
          </div>
        </div>
      </Heading>
      {searchValue && match.length === 0 ? <span>No data found</span> : null}
      {filterConfiguration.scopeFilters.length > 0 ? (
        <FilterSummary filters={filterConfiguration.scopeFilters} onChange={applyFilter} />
      ) : null}
      <Container>
        <TreeViewer
          scopes={pathScopes}
          include={include}
          exclude={exclude}
          nodes={hierarchyData}
          scopeFilterConfiguration={filterConfiguration}
          searchHit={match}
        />
        <ScopeSummary scopes={pathScopes} data={hierarchyData} />
      </Container>
    </RowContainer>
  )
}
