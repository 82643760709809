import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useRemoteData } from 'lib/RemoteData'
import { API_HOST } from 'lib'
import { CommonAssortmentDialog } from 'components/CommonAssortmentDialog'
import { FileStreamOverviewDTO } from '../file-stream-overview/FileStreamOverview'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { SRD } from 'srd'
import { createCommonAssortment } from '../../services/CommonAssortmentService'
import { CommonsAndInstallationsContext } from '../../domain/CommonsAndInstallationsContext'
import { ArrowRightIcon } from '../../icons'
import HomeIcon from '../../icons/HomeIcon'

function LoadingSpinner() {
  return (
    <div className="card">
      <div data-testid="loading">
        <div className="loadingSpinner centerAbsolute" />
      </div>
    </div>
  )
}

export const CommonAssortmentCreate = () => {
  const navigate = useNavigate()
  const { data: commonsAndInstallationsRemoteData, refresh: refreshCommonsAndInstallations } =
    useContext(CommonsAndInstallationsContext)
  const { data: fileStreamsRemoteData } = useRemoteData<FileStreamOverviewDTO[]>(`${API_HOST}/v1/filestream`)
  const { selectedCountry } = useContext(SelectedCountryContext)

  const combinedRemoteData = SRD.map2(
    (commonsAndInstallations, fileStreams) => ({ commonsAndInstallations, fileStreams }),
    commonsAndInstallationsRemoteData,
    fileStreamsRemoteData
  )

  return SRD.match(
    {
      notAsked: () => <LoadingSpinner />,
      loading: () => <LoadingSpinner />,
      failure: (err) => (
        <div className="card">
          <div className="centerAbsolute">
            <div className="alert alert-danger">
              Uh-oh.. Failed to load file streams
              <div>{err.body?.title}</div>
            </div>
          </div>
        </div>
      ),
      success: ({ commonsAndInstallations, fileStreams }) => {
        const availableInCountry = fileStreams.filter((fs) => fs.country === selectedCountry)
        const fileStreamsData = availableInCountry.map((fs) => ({
          name: fs.name,
          id: fs.streamId,
          type: fs.fileType,
        }))

        return (
          <div>
            <div className="py-md px-lg flex items-center space-x-md">
              <Link aria-label="Home" to={'/'}>
                <HomeIcon size="1.5rem" />
              </Link>
              <ArrowRightIcon size="1rem" />
              <Link to="/common-assortment">
                <h2>Common</h2>
              </Link>
              <ArrowRightIcon size="1rem" />
              <h2>Create</h2>
            </div>
            <CommonAssortmentDialog
              fileStreams={fileStreamsData}
              commonsAndInstallations={commonsAndInstallations}
              onComplete={(fields) => {
                return createCommonAssortment(fields).then((response) => {
                  refreshCommonsAndInstallations()
                  navigate('/common-assortment/' + response?.data?.id ?? '')
                })
              }}
            />
          </div>
        )
      },
    },
    combinedRemoteData
  )
}
