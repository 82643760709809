import React, { useEffect, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { AppMenuMountDiv } from './AppMountMenuDiv'
import { authentication } from '../lib'
import { LogoutIcon, Logo } from '../icons'
import { colors } from '../styles'

type HeaderProps = {
  children?: Node
  country: {
    accessibleCountries: string[]
    selectCountry: (country: string) => void
  }
}

export const Header = ({ country }: HeaderProps) => {
  const { selectedCountry } = useContext(SelectedCountryContext)
  useEffect(() => {
    if (country.accessibleCountries.length > 0 && !country.accessibleCountries.includes(selectedCountry)) {
      country.selectCountry(country.accessibleCountries[0])
    }
  }, [country, country.accessibleCountries, selectedCountry])

  const userEmail = authentication.getParsedAccessToken().email ?? ''
  return (
    <div className="navbar">
      <div className="w-full mx-auto grid grid-cols-5">
        <div className="flex items-center">
          <Link className="pl-sm inline-flex" to={'/'}>
            <Logo />
            <span className="mt-sm ml-md">Assortment Manager</span>
          </Link>
        </div>

        <NavLink className="navtab " to="/file-stream">
          File Streams
        </NavLink>
        <NavLink className="navtab" to="/common-assortment">
          Common
        </NavLink>
        <NavLink className="navtab" to="/installations">
          Installations
        </NavLink>

        <div className="flex items-center justify-end">
          <select
            className="select text-black w-auto mr-md"
            disabled={country.accessibleCountries.length <= 1}
            value={selectedCountry}
            onChange={(e) => country.selectCountry(e.target.value)}
          >
            {country.accessibleCountries.map((accessible) => {
              return (
                <option key={accessible} value={accessible}>
                  {accessible}
                </option>
              )
            })}
          </select>
          <AppMenuMountDiv />
          <button
            className="btn btn-icon text-white"
            aria-label="Log out"
            onClick={authentication.logout}
            title={`Log out ${userEmail}`}
          >
            <LogoutIcon color={colors.WHITE} />
          </button>
        </div>
      </div>
    </div>
  )
}
