import React from 'react'
import { getStatusEmoji, getStatusText, UiStatus } from 'domain/FileStatus'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

type Props = {
  statusCounts: [UiStatus, number, string][]
}

const FileStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const FileStatusItem = styled.div`
  display: flex;
`

export const CommonAssortmentStatusCell = ({ statusCounts }: Props) => {
  return (
    <FileStatusContainer>
      {statusCounts
        .filter(([status]) => status !== 'NOT_COMPATIBLE')
        .map(([status, count, link]) => {
          const icon = getStatusEmoji(status)
          return (
            <FileStatusItem key={status} title={getStatusText(status)}>
              <Link to={link}>
                {icon} <span className="link">{count}</span>
              </Link>
            </FileStatusItem>
          )
        })}
    </FileStatusContainer>
  )
}
