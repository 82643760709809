import React, { useState } from 'react'
import {
  Config,
  countAffectedRows,
  DisableAllActionType,
  DisabledActionType,
  isCommon,
  isDisableAllAction,
  isDisabledAction,
  isInstallation,
  isRefundGroupAction,
  isReturnAction,
  isSuperGroupAction,
  ReturnActionType,
  saveActions,
  ShapeAction,
  ShapeActionType,
  stagedIfChanged,
  withoutUndefinedActions,
} from 'domain/Assortment'
import { Dialog } from '../../Dialog'
import { StagedModifications } from '../StagedModifications'
import { DisabledShapeActions, ShapeActionDialog, ShapeActionSnapshot } from './ShapeActionDialog'
import { Action, EditableActions, FinalStatus, StagedAction } from '../CommonComponents'
import { BottleGroup, Modification, ShapeFile } from 'domain/DTO'
import { SortableHeader, useTableSorting } from '../TableColumnSort'
import { paginationOptions } from '../AssortmentDatabase'
import { getPageData, PagedTableControls } from '@tomra/react-table'
import { logError } from '../../../lib/logError'

const ReturnValue = ({ value }: { value: number }) => {
  const displayValue = value === 0 ? 'Accepted' : 'Returned'
  return <div>{displayValue}</div>
}

const RefundGroupValue = ({ value }: { value: number }) => {
  return <div>{`Refund group ${value}`}</div>
}

const SuperGroupValue = ({ value }: { value: number }) => {
  return <div>{`Super group ${value}`}</div>
}

type DialogState = {
  show: boolean
  id: string
  snapshot: ShapeActionSnapshot
  disabled: DisabledShapeActions
}

type Props = {
  config: Config
  database: ShapeFile
  commonModifications: Modification[]
  installationModifications: Modification[]
  modificationUrl: string
  refreshModifications(): void
}

export const ShapeTable = ({
  config,
  database,
  commonModifications,
  installationModifications,
  modificationUrl,
  refreshModifications,
}: Props) => {
  const [dialogState, setDialogState] = useState({ show: false } as DialogState)
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPrPage, setRecordsPrPage] = useState(100)
  const [filter, setFilter] = useState('')
  const [staged, setStaged] = useState<{ [key: string]: ShapeAction | undefined }>({})
  const [showDetailed, setShowDetailed] = useState(false)

  const common = createShapeActions(commonModifications)
  const override = isInstallation(config) ? createShapeActions(installationModifications) : null
  const editableActions = isInstallation(config) && override != null ? override : common

  const sorting = useTableSorting<BottleGroup>(
    {
      'Bottle Group': (bg) => bg.groupNumber,
      Name: (bg) => bg.name,
      CommonConfig: (bg) => {
        const id = bg.groupNumber
        return (
          [returnActionId(id), disabledActionId(id), refundGroupActionId(id), superGroupActionId(id)]
            .map((id) => common[id] ?? staged[id])
            .filter((action) => !!action)[0]
            ?.type.toString() ?? ''
        )
      },
      Override: (bg) => {
        const id = bg.groupNumber
        return (
          [returnActionId(id), disabledActionId(id), refundGroupActionId(id), superGroupActionId(id)]
            .map((id) => override?.[id] ?? staged[id])
            .filter((action) => !!action)[0]
            ?.type.toString() ?? ''
        )
      },
      Accepted: (bg) => {
        return calculateFinalStatus(bg, common, override || {}, staged).toString()
      },
    },
    'Bottle Group'
  )

  const rows = sorting.sort(
    database.bottleGroups.filter((bg) =>
      `${bg.name}-bottle ${bg.groupNumber}-super ${bg.superGroupNumber}-refund ${bg.refundGroup}`
        .toLocaleLowerCase()
        .includes(filter)
    )
  )
  return (
    <div>
      <div className="flex my-md pl-lg items-center justify-between">
        <div>
          <StagedModifications numberOfStaged={getNumberOfStaged()} onClear={resetStaged} onApply={applyStaged} />
        </div>
        {isCommon(config) ? (
          <div style={{ marginRight: 40 }}>
            <label>
              Disable all Bottle Groups
              <input
                type="checkbox"
                className="checkbox ml-sm align-middle"
                checked={hasDisableAllAction()}
                onChange={onUserPressedDisableAll}
              />
            </label>
          </div>
        ) : null}
      </div>
      <div className="inline-flex my-sm pl-lg">
        <label>
          Search:
          <input
            type="text"
            className="mx-md"
            style={{ width: '70%' }} // overrides input[type="text"] {width: 100%}
            data-testid="search"
            onChange={(e) => {
              setFilter(e.target.value.toLocaleLowerCase())
              setCurrentPage(1)
            }}
          />
        </label>
        <abbr
          className="no-underline pt-sm"
          title={
            'You can search for bottle, refund and super groups explicitly like this: `super 12`, `refund 18`, `bottle 122`'
          }
        >
          {'❔'}
        </abbr>
      </div>
      <div className="my-sm pl-lg">
        <label>
          Show Details:
          <input
            type="checkbox"
            className="checkbox align-middle ml-sm"
            checked={showDetailed}
            onChange={(e) => setShowDetailed(e.target.checked)}
          />
        </label>
      </div>
      <table className="table table-fixed">
        <thead>
          <tr>
            <SortableHeader name="Bottle Group" currentSort={sorting.currentSort} />
            <SortableHeader name="Name" currentSort={sorting.currentSort} />
            <th>Database</th>
            <th>Disable All</th>
            <SortableHeader name="CommonConfig" currentSort={sorting.currentSort} />
            {isInstallation(config) && <SortableHeader name="Override" currentSort={sorting.currentSort} />}
            <SortableHeader name="Accepted" currentSort={sorting.currentSort} />
          </tr>
        </thead>
        <tbody>
          {getPageData(rows, currentPage, recordsPrPage).map((bottleGroup) => {
            const id = bottleGroup.groupNumber
            return (
              <tr key={id}>
                <td>{bottleGroup.groupNumber}</td>
                <td>{bottleGroup.name}</td>
                <td>
                  <ReturnValue value={bottleGroup.returnFlag} />
                  {showDetailed ? (
                    <>
                      {' '}
                      <RefundGroupValue value={bottleGroup.refundGroup} />
                      <SuperGroupValue value={bottleGroup.superGroupNumber} />{' '}
                    </>
                  ) : null}
                </td>
                <td>
                  {[disabledAllActionId()]
                    .filter((id) => common[id] || staged[id])
                    .map((id) => (
                      <StagedAction key={id} original={common[id]} staged={staged[id]} />
                    ))}
                </td>
                {isInstallation(config) ? (
                  <td>
                    {[returnActionId(id), disabledActionId(id), refundGroupActionId(id), superGroupActionId(id)]
                      .filter((id) => common[id])
                      .map((id) => (
                        <Action key={id} action={common[id]} />
                      ))}
                  </td>
                ) : null}
                <td>
                  <EditableActions onClick={() => openDialog(id)}>
                    {[returnActionId(id), disabledActionId(id), refundGroupActionId(id), superGroupActionId(id)]
                      .filter((id) => editableActions[id] || staged[id])
                      .map((id) => (
                        <StagedAction key={id} original={editableActions[id]} staged={staged[id]} />
                      ))}
                  </EditableActions>
                </td>
                <td>
                  <FinalStatus ok={calculateFinalStatus(bottleGroup, common, override || {}, staged)} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <PagedTableControls
        currentPage={currentPage}
        numOfItems={rows.length}
        recordsPerPage={recordsPrPage}
        onPageUpdate={setCurrentPage}
        onRecordsPerPageChange={(recordsPerPage: number) => {
          setRecordsPrPage(recordsPerPage)
          setCurrentPage(1)
        }}
        recordsPerPageOptions={paginationOptions}
      />
      {dialogState.show ? (
        <Dialog onClose={() => setDialogState({ ...dialogState, show: false })} shouldCloseOnOverlayClick={false}>
          <ShapeActionDialog
            config={config}
            id={dialogState.id}
            snapshot={dialogState.snapshot}
            disabled={dialogState.disabled}
            onApply={saveToStaged}
          />
        </Dialog>
      ) : null}
    </div>
  )

  function getNumberOfStaged() {
    return staged[disabledAllActionId()]
      ? countAffectedRows(Object.keys(withoutUndefinedActions(staged))) + rows.length - 1
      : countAffectedRows(Object.keys(withoutUndefinedActions(staged)))
  }

  function resetStaged() {
    setStaged({})
  }

  async function applyStaged() {
    try {
      await saveActions('SHAPE', modificationUrl, editableActions, staged)
      setStaged({})
      refreshModifications()
    } catch (error: any) {
      logError(error)
      // TODO: show error in ui
    }
  }

  function saveToStaged(id: string, snapshot: ShapeActionSnapshot) {
    setStaged({
      ...staged,
      [returnActionId(id)]: stagedIfChanged(snapshot.returnAction, editableActions[returnActionId(id)]),
      [disabledActionId(id)]: stagedIfChanged(snapshot.disabledAction, editableActions[disabledActionId(id)]),
      [refundGroupActionId(id)]: stagedIfChanged(snapshot.refundGroupAction, editableActions[refundGroupActionId(id)]),
      [superGroupActionId(id)]: stagedIfChanged(snapshot.superGroupAction, editableActions[superGroupActionId(id)]),
    })
    setDialogState({ ...dialogState, show: false })
  }

  function hasDisableAllAction() {
    const disabledAllAction = staged[disabledAllActionId()] ?? common[disabledAllActionId()] ?? { type: 'UNSET' }
    return disabledAllAction.type !== 'UNSET'
  }

  function onUserPressedDisableAll() {
    const currentDisableAllAction = hasDisableAllAction()
      ? { type: 'UNSET' as ShapeActionType }
      : { type: DisableAllActionType.SET }
    setStaged({
      ...staged,
      [disabledAllActionId()]: stagedIfChanged(currentDisableAllAction, common[disabledAllActionId()]),
    })
  }

  function openDialog(id: string) {
    setDialogState({
      show: true,
      id: id,
      snapshot: {
        returnAction: staged[returnActionId(id)] ?? editableActions[returnActionId(id)],
        disabledAction: staged[disabledActionId(id)] ?? editableActions[disabledActionId(id)],
        refundGroupAction: staged[refundGroupActionId(id)] ?? editableActions[refundGroupActionId(id)],
        superGroupAction: staged[superGroupActionId(id)] ?? editableActions[superGroupActionId(id)],
      },
      disabled: {
        disabledAction:
          isInstallation(config) &&
          typeof common[disabledActionId(id)]?.allowOverride !== 'undefined' &&
          !common[disabledActionId(id)]?.allowOverride,
        returnAction:
          isInstallation(config) &&
          typeof common[returnActionId(id)]?.allowOverride !== 'undefined' &&
          !common[returnActionId(id)]?.allowOverride,
        refundGroupAction:
          isInstallation(config) &&
          typeof common[refundGroupActionId(id)]?.allowOverride !== 'undefined' &&
          !common[refundGroupActionId(id)]?.allowOverride,
        superGroupAction:
          isInstallation(config) &&
          typeof common[superGroupActionId(id)]?.allowOverride !== 'undefined' &&
          !common[superGroupActionId(id)]?.allowOverride,
      },
    })
  }
}

const calculateFinalStatus = (
  original: BottleGroup,
  common: { [key: string]: ShapeAction },
  override: { [key: string]: ShapeAction },
  staged: { [key: string]: ShapeAction | undefined }
) => {
  const returned =
    staged[returnActionId(original.groupNumber)] ??
    override[returnActionId(original.groupNumber)] ??
    common[returnActionId(original.groupNumber)]
  const disabled =
    staged[disabledActionId(original.groupNumber)] ??
    override[disabledActionId(original.groupNumber)] ??
    common[disabledActionId(original.groupNumber)]
  const disabledAll = staged[disabledAllActionId()] ?? common[disabledAllActionId()]

  const accepted =
    (original.returnFlag === 0 && returned?.type !== ReturnActionType.SET) ||
    (original.returnFlag === 1 && returned?.type === ReturnActionType.CLEAR)

  const enabled =
    (disabledAll?.type !== DisableAllActionType.SET && disabled?.type !== DisabledActionType.SET) ||
    (disabledAll?.type === DisableAllActionType.SET && disabled?.type === DisabledActionType.CLEAR)

  return accepted && enabled
}

const createShapeActions = (modifications: Modification[]): { [key: string]: ShapeAction } => {
  return modifications.reduce((actions, modification) => {
    const actionType = modification.actionType
    if (actionType) {
      if (isReturnAction(actionType)) {
        actions[returnActionId(modification.referenceObjectId)] = {
          type: actionType,
          allowOverride: modification.allowedOverride,
        }
      } else if (isDisabledAction(actionType)) {
        actions[disabledActionId(modification.referenceObjectId)] = {
          type: actionType,
          allowOverride: modification.allowedOverride,
        }
      } else if (isRefundGroupAction(actionType)) {
        actions[refundGroupActionId(modification.referenceObjectId)] = {
          type: actionType,
          value: modification.actionValue,
          allowOverride: modification.allowedOverride,
        }
      } else if (isSuperGroupAction(actionType)) {
        actions[superGroupActionId(modification.referenceObjectId)] = {
          type: actionType,
          value: modification.actionValue,
          allowOverride: modification.allowedOverride,
        }
      } else if (isDisableAllAction(actionType)) {
        actions[disabledAllActionId()] = {
          type: actionType,
          allowOverride: modification.allowedOverride,
        }
      }
    }
    return actions
  }, {} as { [key: string]: ShapeAction })
}

const returnActionId = (id: string) => {
  return id + '[RETURN]'
}
const disabledActionId = (id: string) => {
  return id + '[DISABLE]'
}
const disabledAllActionId = () => {
  return '[DISABLE_ALL]'
}
const refundGroupActionId = (id: string) => {
  return id + '[REFUND_GROUP]'
}
const superGroupActionId = (id: string) => {
  return id + '[SUPER_GROUP]'
}
