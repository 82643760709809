import React from 'react'
import styled from 'styled-components'
import { spacing } from './index'

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${spacing.md};
  margin-left: ${spacing.md};
  max-width: 16000px;
  > *:not(:last-child) {
    margin-right: ${spacing.md};
  }
`

const Content = styled.div`
  padding: ${spacing.xl};
  width: 700px;
  h1 {
    margin: 0;
  }
`

const FooterContainer = styled.div`
  padding-top: ${spacing.lg};
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: ${spacing.md};
  }
`

const ErrorText = styled.p`
  color: red;
`

const LoadingButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  > span {
    margin-left: ${spacing.sm};
  }
`

type LoadingButtonTextProps = {
  loading: boolean
  text: string
  loadingText: string
}
const LoadingButtonText = ({ loading, text, loadingText }: LoadingButtonTextProps) => {
  return (
    <LoadingButtonContainer>
      {loading && <div className="loadingSpinner text-sm" />}
      <span>{loading ? loadingText : text}</span>
    </LoadingButtonContainer>
  )
}

export const FormLayout = {
  InnerContainer,
  Content,
  FooterContainer,
  Footer,
  ErrorText,
  LoadingButtonText,
}
