import { fetch } from '@tomra/client-side-http-client'
import { authentication } from './authentication'
import { HttpError } from './RemoteData'

const config = {
  fetchNewToken: authentication.fetchNewToken,
  getToken: authentication.getToken,
  timeoutInMs: 120000, // override default of 20 sec because ass-man is sloooow :(
}

export const extractErrorMessage = (err: HttpError) => {
  throw Error(err.body.detail)
}

export function authenticatedPost(url: string, options: RequestInit, timeoutInMs: number): Promise<any> {
  return fetch(url, { ...options, method: 'POST' }, { ...config, timeoutInMs: timeoutInMs }).run()
}

export function authenticatedFetch(url: string, options: RequestInit, retryCount?: number): Promise<any> {
  return fetch(url, options, { ...config, retryCount }).run()
}

export function authenticatedGet(url: string, returnRawResponse: boolean = false) {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    returnRawResponse,
  }

  return authenticatedFetch(`${url}`, options)
}
