import React, { useState } from 'react'
import {
  AcceptActionType,
  Config,
  countAffectedRows,
  CrateAction,
  InAssortmentActionType,
  isAcceptActionType,
  isInAssortmentActionType,
  isInstallation,
  saveActions,
  stagedIfChanged,
  withoutUndefinedActions,
} from 'domain/Assortment'
import { Dialog } from '../../Dialog'
import { StagedModifications } from '../StagedModifications'
import { CrateActionDialog, CrateActionSnapshot, DisabledCrateActions } from './CrateActionDialog'
import { Action, EditableActions, FinalStatus, StagedAction } from '../CommonComponents'
import { CrateFile, CrateGroup, Modification } from 'domain/DTO'
import { SortableHeader, useTableSorting } from '../TableColumnSort'
import { paginationOptions } from '../AssortmentDatabase'
import { getPageData, PagedTableControls } from '@tomra/react-table'
import { logError } from '../../../lib/logError'

const AcceptedValue = ({ accepted }: { accepted: boolean }) => {
  return <div>{accepted ? 'Accepted' : 'Rejected'}</div>
}

const InAssortmentValue = ({ inAssortment }: { inAssortment: boolean }) => {
  return <div>{inAssortment ? 'In assortment' : 'Not in assortment'}</div>
}

type DialogState = {
  show: boolean
  id?: string
  snapshot: CrateActionSnapshot
  disabled: DisabledCrateActions
}

type Props = {
  config: Config
  database: CrateFile
  commonModifications: Modification[]
  installationModifications: Modification[]
  modificationUrl: string
  refreshModifications(): void
}

export const CrateTable = ({
  config,
  database,
  commonModifications,
  installationModifications,
  modificationUrl,
  refreshModifications,
}: Props) => {
  const [dialogState, setDialogState] = useState({ show: false } as DialogState)
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPrPage, setRecordsPrPage] = useState(100)
  const [filter, setFilter] = useState('')
  const [staged, setStaged] = useState<{ [key: string]: CrateAction | undefined }>({})

  const common = createCrateActions(commonModifications)
  const override = isInstallation(config) ? createCrateActions(installationModifications) : null
  const editableActions = override ?? common

  const sorting = useTableSorting<CrateGroup>(
    {
      'Bottle Group': (cg) => cg.id,
      Name: (cg) => cg.name,
      CommonConfig: (cg) => {
        const id = cg.id
        return (
          [acceptActionId(id), inAssortmentActionId(id)]
            .map((id) => common[id] ?? staged[id])
            .filter((action) => !!action)[0]
            ?.type.toString() ?? ''
        )
      },
      Override: (cg) => {
        const id = cg.id
        return (
          [acceptActionId(id), inAssortmentActionId(id)]
            .map((id) => override?.[id] ?? staged[id])
            .filter((action) => !!action)[0]
            ?.type.toString() ?? ''
        )
      },
      Accepted: (cg) => {
        return calculateFinalStatus(cg, common, override || {}, staged).toString()
      },
    },
    'Bottle Group'
  )

  const rows = sorting.sort(
    Array.from(database.crateGroups).filter((cg) =>
      `${cg.name}-id ${cg.id}`.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    )
  )

  return (
    <div>
      <div className="my-md pl-lg">
        <StagedModifications numberOfStaged={getNumberOfStaged()} onClear={resetStaged} onApply={applyStaged} />
      </div>

      <div className="inline-flex my-sm pl-lg">
        <label>
          Search:
          <input
            type="text"
            className="mx-md"
            style={{ width: '70%' }} // overrides input[type="text"] {width: 100%}
            data-testid="search"
            onChange={(e) => {
              setFilter(e.target.value.toLocaleLowerCase())
              setCurrentPage(1)
            }}
          />
        </label>
        <abbr
          className="no-underline pt-sm"
          title={'You can search for crate names or id`s. Explicit id only: `id 12`'}
        >
          {'❔'}
        </abbr>
      </div>
      <table className="table table-fixed">
        <thead>
          <tr>
            <SortableHeader name="Bottle Group" currentSort={sorting.currentSort} />
            <SortableHeader name="Name" currentSort={sorting.currentSort} />
            <th>Database</th>
            <SortableHeader name="CommonConfig" currentSort={sorting.currentSort} />
            {isInstallation(config) && <SortableHeader name="Override" currentSort={sorting.currentSort} />}
            <SortableHeader name="Accepted" currentSort={sorting.currentSort} />
          </tr>
        </thead>
        <tbody>
          {getPageData(rows, currentPage, recordsPrPage).map((crateGroup) => {
            const id = crateGroup.id.toString()
            return (
              <tr key={id}>
                <td>{id}</td>
                <td>{crateGroup.name}</td>
                <td>
                  <AcceptedValue accepted={crateGroup.accepted} />
                  <InAssortmentValue inAssortment={crateGroup.inAssortment} />
                </td>
                {isInstallation(config) ? (
                  <td>
                    {[acceptActionId(id), inAssortmentActionId(id)]
                      .filter((id) => common[id])
                      .map((id) => (
                        <Action key={id} action={common[id]} />
                      ))}
                  </td>
                ) : null}
                <td>
                  <EditableActions onClick={() => openDialog(id)}>
                    {[acceptActionId(id), inAssortmentActionId(id)]
                      .filter((id) => editableActions[id] || staged[id])
                      .map((id) => (
                        <StagedAction key={id} original={editableActions[id]} staged={staged[id]} />
                      ))}
                  </EditableActions>
                </td>
                <td>
                  <FinalStatus ok={calculateFinalStatus(crateGroup, common, override || {}, staged)} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <PagedTableControls
        currentPage={currentPage}
        numOfItems={rows.length}
        recordsPerPage={recordsPrPage}
        onPageUpdate={setCurrentPage}
        recordsPerPageOptions={paginationOptions}
        onRecordsPerPageChange={(recordsPerPage: number) => {
          setRecordsPrPage(recordsPerPage)
          setCurrentPage(1)
        }}
      />
      {dialogState.show && dialogState.id ? (
        <Dialog onClose={() => setDialogState({ ...dialogState, show: false })} shouldCloseOnOverlayClick={false}>
          <CrateActionDialog
            config={config}
            id={dialogState.id}
            snapshot={dialogState.snapshot}
            disabled={dialogState.disabled}
            onApply={(id, snapshot) => {
              onUserEditedActions(id, snapshot)
              setDialogState({ ...dialogState, show: false })
            }}
          />
        </Dialog>
      ) : null}
    </div>
  )

  function getNumberOfStaged() {
    return countAffectedRows(Object.keys(withoutUndefinedActions(staged)))
  }

  function resetStaged() {
    setStaged({})
  }

  async function applyStaged() {
    try {
      await saveActions('CRATE', modificationUrl, editableActions, staged)
      setStaged({})
      refreshModifications()
    } catch (error: any) {
      logError(error)
    }
  }

  function onUserEditedActions(id: string, snapshot: CrateActionSnapshot) {
    setStaged({
      ...staged,
      [acceptActionId(id)]: stagedIfChanged(snapshot.acceptAction, editableActions[acceptActionId(id)]),
      [inAssortmentActionId(id)]: stagedIfChanged(
        snapshot.inAssortmentAction,
        editableActions[inAssortmentActionId(id)]
      ),
    })
  }

  function openDialog(id: string) {
    setDialogState({
      show: true,
      id: id,
      snapshot: {
        acceptAction: staged[acceptActionId(id)] ?? editableActions[acceptActionId(id)],
        inAssortmentAction: staged[inAssortmentActionId(id)] ?? editableActions[inAssortmentActionId(id)],
      },
      disabled: {
        acceptAction:
          isInstallation(config) &&
          typeof common[acceptActionId(id)]?.allowOverride !== 'undefined' &&
          !common[acceptActionId(id)]?.allowOverride,
        inAssortmentAction:
          isInstallation(config) &&
          typeof common[inAssortmentActionId(id)]?.allowOverride !== 'undefined' &&
          !common[inAssortmentActionId(id)]?.allowOverride,
      },
    })
  }
}

const calculateFinalStatus = (
  original: CrateGroup,
  common: { [key: string]: CrateAction },
  override: { [key: string]: CrateAction },
  staged: { [key: string]: CrateAction | undefined }
) => {
  const acceptedAction =
    staged[acceptActionId(original.id)] ?? override[acceptActionId(original.id)] ?? common[acceptActionId(original.id)]
  const inAssortmentAction =
    staged[inAssortmentActionId(original.id)] ??
    override[acceptActionId(original.id)] ??
    common[inAssortmentActionId(original.id)]

  const accepted =
    (original.accepted && acceptedAction?.type !== AcceptActionType.CLEAR) ||
    (!original.accepted && acceptedAction?.type === AcceptActionType.SET)
  const inAssortment =
    (original.inAssortment && inAssortmentAction?.type !== InAssortmentActionType.CLEAR) ||
    (!original.inAssortment && inAssortmentAction?.type === InAssortmentActionType.SET)

  return accepted && inAssortment
}

const createCrateActions = (modifications: Modification[]): { [key: string]: CrateAction } => {
  return modifications.reduce((actions, modification) => {
    const actionType = modification.actionType
    if (actionType) {
      if (isAcceptActionType(actionType)) {
        actions[acceptActionId(modification.referenceObjectId)] = {
          type: actionType,
          allowOverride: modification.allowedOverride,
        }
      } else if (isInAssortmentActionType(actionType)) {
        actions[inAssortmentActionId(modification.referenceObjectId)] = {
          type: actionType,
          allowOverride: modification.allowedOverride,
        }
      }
    }
    return actions
  }, {} as { [key: string]: CrateAction })
}

const acceptActionId = (id: string) => {
  return id + '[ACCEPT]'
}
const inAssortmentActionId = (id: string) => {
  return id + '[IN_ASSORTMENT]'
}
