import React, { useState } from 'react'
import { FormLayout } from 'styles/FormLayout'
import { CrateAction, AcceptActionType, InAssortmentActionType, Config } from 'domain/Assortment'
import { SelectAction } from '../CommonComponents'

export type CrateActionSnapshot = {
  acceptAction?: CrateAction
  inAssortmentAction?: CrateAction
}

export type DisabledCrateActions = {
  acceptAction: boolean
  inAssortmentAction: boolean
}

type Props = {
  config: Config
  id: string
  snapshot: CrateActionSnapshot
  disabled: DisabledCrateActions
  onApply: (id: string, snapshot: CrateActionSnapshot) => void
}

export const CrateActionDialog = ({ config, id, snapshot, disabled, onApply }: Props) => {
  const [acceptAction, setAcceptedAction] = useState<CrateAction | undefined>(snapshot.acceptAction)
  const [inAssortmentAction, setInAssortmentAction] = useState<CrateAction | undefined>(snapshot.inAssortmentAction)
  return (
    <div className="card">
      <FormLayout.Content>
        <h3>Active actions</h3>
        <SelectAction
          config={config}
          label={'ACCEPTED action:'}
          type={acceptAction?.type}
          disabled={disabled?.acceptAction ?? false}
          allowOverride={acceptAction?.allowOverride}
          options={[AcceptActionType.SET, AcceptActionType.CLEAR]}
          onTypeChanged={(value) => setAcceptedAction({ ...acceptAction, type: value as AcceptActionType })}
          onAllowOverrideChanged={(allow) => {
            if (acceptAction) {
              setAcceptedAction({ ...acceptAction, allowOverride: allow })
            }
          }}
        />
        <SelectAction
          config={config}
          label={'IN ASSORTMENT action:'}
          type={inAssortmentAction?.type}
          disabled={disabled?.inAssortmentAction ?? false}
          allowOverride={inAssortmentAction?.allowOverride}
          options={[InAssortmentActionType.SET, InAssortmentActionType.CLEAR]}
          onTypeChanged={(value) =>
            setInAssortmentAction({ ...inAssortmentAction, type: value as InAssortmentActionType })
          }
          onAllowOverrideChanged={(allow) => {
            if (inAssortmentAction) {
              setInAssortmentAction({ ...inAssortmentAction, allowOverride: allow })
            }
          }}
        />
        <FormLayout.FooterContainer>
          <FormLayout.Footer>
            <button
              className="btn btn-primary-dark py-sm"
              onClick={() => {
                onApply(id, {
                  acceptAction: acceptAction,
                  inAssortmentAction: inAssortmentAction,
                })
              }}
            >
              <span>Save</span>
            </button>
          </FormLayout.Footer>
        </FormLayout.FooterContainer>
      </FormLayout.Content>
    </div>
  )
}
