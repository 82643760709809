import React from 'react'

type DeleteIconProps = {
  color?: string
  size?: string
}
export const DeleteIcon = (props: DeleteIconProps) => (
  <svg
    fill={props.color || 'var(--colors-black)'}
    height={props.size || '24'}
    width={props.size || '24'}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path className="a" d="M17.25,21H6.75a1.5,1.5,0,0,1-1.5-1.5V6h13.5V19.5A1.5,1.5,0,0,1,17.25,21Z" />
    <line className="a" x1="9.75" y1="16.5" x2="9.75" y2="10.5" />
    <line className="a" x1="14.25" y1="16.5" x2="14.25" y2="10.5" />
    <line className="a" x1="2.25" y1="6" x2="21.75" y2="6" />
    <path className="a" d="M14.25,3H9.75a1.5,1.5,0,0,0-1.5,1.5V6h7.5V4.5A1.5,1.5,0,0,0,14.25,3Z" />
  </svg>
)
