import React, { useState, useEffect, useCallback } from 'react'
import { Header } from './components/Header'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import { CommonAssortmentOverview } from './pages/common-overview/CommonAssortmentOverview'
import { CommonAssortment } from './pages/common/CommonAssortment'
import { FileStreamOverview } from './pages/file-stream-overview/FileStreamOverview'
import { FileStream } from './pages/file-stream/FileStream'
import { InstallationsOverview } from './pages/installations/InstallationsOverview'
import { InstallationView } from './pages/installations/InstallationView'
import { useRemoteData, useAutoRefresh } from 'lib/RemoteData'
import { CommonsAndInstallationsContextData } from 'domain/DTO'
import { API_HOST, VERSION } from 'lib'
import { SRD } from 'srd'
import { CommonAssortmentEdit } from './pages/common/CommonAssortmentEdit'
import { CommonAssortmentCreate } from './pages/common/CommonAssortmentCreate'
import { CommonsAndInstallationsContext } from 'domain/CommonsAndInstallationsContext'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'

function Root() {
  const [selectedCountry, setSelectedCountry] = useLocalStorageState('assortmentManagementSelectedCountry', 'DE')
  const installationsUrl = `${API_HOST}/v1/installations?country=${selectedCountry}`
  const { data: contextRemoteData, refresh: refreshContext } =
    useRemoteData<CommonsAndInstallationsContextData>(installationsUrl)
  useAutoRefresh(refreshContext, 180000)
  const accessibleCountries = SRD.isSuccess(contextRemoteData) ? contextRemoteData.data.countries : []
  const country = {
    accessibleCountries,
    selectCountry: setSelectedCountry,
  }

  return (
    <CommonsAndInstallationsContext.Provider value={{ data: contextRemoteData, refresh: refreshContext }}>
      <SelectedCountryContext.Provider value={{ selectedCountry }}>
        <Header country={country} />
        <div className="mt-md mx-md">
          <Outlet />
        </div>
        <div className="mx-md">Version: {VERSION}</div>
      </SelectedCountryContext.Provider>
    </CommonsAndInstallationsContext.Provider>
  )
}

export const routes = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/common-assortment/new',
        element: <CommonAssortmentCreate />,
      },
      {
        path: '/common-assortment/:id',
        element: <CommonAssortment />,
      },
      {
        path: '/common-assortment/:id/edit',
        element: <CommonAssortmentEdit />,
      },
      {
        path: '/common-assortment',
        element: <CommonAssortmentOverview />,
      },
      {
        path: '/file-stream/:id',
        element: <FileStream />,
      },
      {
        path: '/file-stream',
        element: <FileStreamOverview />,
      },
      {
        path: '/installation/:id',
        element: <InstallationView />,
      },
      {
        path: '/installations',
        element: <InstallationsOverview />,
      },
      {
        path: '/',
        element: <CommonAssortmentOverview />,
      },
      {
        path: '*',
        element: <h1>404</h1>,
      },
    ],
  },
]

function useLocalStorageState(localStorageKey: string, defaultValue: string): [string, (value: string) => void] {
  const existingValue = localStorage.getItem(localStorageKey)
  if (existingValue) {
    defaultValue = existingValue
  }

  const [value, setValue] = useState<string>(defaultValue)

  const setValueAndLocalStorage = useCallback(
    (newValue: string) => {
      localStorage.setItem(localStorageKey, newValue)
      setValue(newValue)
    },
    [localStorageKey]
  )

  useEffect(() => {
    const localStorageValue = localStorage.getItem(localStorageKey)
    if (localStorageValue) {
      setValue(localStorageValue)
    } else {
      setValueAndLocalStorage(defaultValue)
    }
  }, [defaultValue, localStorageKey, setValueAndLocalStorage])

  return [value, setValueAndLocalStorage]
}

export function App() {
  const router = createBrowserRouter(routes)
  return <RouterProvider router={router} />
}
