import React from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../styles'

const StyledSelect = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  background-color: ${colors.GREY};
  padding: ${spacing.sm} ${(props) => (props.disabled ? spacing.md : spacing.xl)} ${spacing.sm} ${spacing.md};
  border: none;
  color: initial;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  :focus {
    outline: none;
  }
`

type Props = {
  onChange: Function
  children: React.ReactNode
  className?: string
  disabled?: boolean
  width?: number
  value?: string
}

export const Select = (props: Props) => (
  <StyledSelect
    onChange={(event) => props.onChange(event.target.value)}
    disabled={props.disabled}
    value={props.value}
    style={{ width: props.width }}
  >
    {props.children}
  </StyledSelect>
)
