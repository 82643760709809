import React, { useCallback, useContext, useState } from 'react'
import { Dialog } from './Dialog'
import { FormLayout } from 'styles/FormLayout'
import { ColumnContainer, InnerContainer, Label } from 'styles/PageLayout'
import { MasterdataAssignment } from './masterdata-assignment/MasterdataAssignment'
import { useRemoteData } from 'lib/RemoteData'
import { API_HOST } from 'lib'
import { ScopeSummary } from './masterdata-assignment/ScopeSummary'
import {
  fromInstallationScope,
  InstallationScopes,
  PathScope,
  StructureNode,
  toInstallationScope,
} from 'lib/InstallationScope'
import { CommonsAndInstallationsContext } from 'domain/CommonsAndInstallationsContext'
import { SelectedCountryContext } from 'domain/SelectedCountryContext'
import { getClaimedScopesMap } from '../services/CommonAssortmentService'
import { SRD } from 'srd'
import { logError } from '../lib/logError'

type Fields = {
  name: string
  installationScopes: InstallationScopes
}

type Props = {
  duplicatingFrom: string
  onComplete: (fields: Fields) => Promise<void>
}

export const DuplicateCommonAssortmentDialog = ({ onComplete, duplicatingFrom }: Props) => {
  const { refresh, data: commonsAndInstallations } = useContext(CommonsAndInstallationsContext)
  const [showInstallationScopeConfiguration, setShowInstallationScopeConfiguration] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [name, setName] = useState('')
  const [modifiedScopes, setModifiedScopes] = useState<PathScope[]>([])
  const { selectedCountry } = useContext(SelectedCountryContext)

  const transform = useCallback((original: { data: unknown }) => {
    const structureNodes = original.data as StructureNode[]
    const pathScopes = fromInstallationScope({ scopes: [] }, structureNodes)
    setModifiedScopes(pathScopes)
    return {
      structureNodes,
    }
  }, [])
  const { data: masterdataTreeRemoteData } = useRemoteData(
    `${API_HOST}/v1/masterdata-hierarchy-tree?country=${selectedCountry}`,
    transform
  )

  const combinedRemoteData = SRD.map2(
    (commonsAndInstallations, masterdataTree) => ({ commonsAndInstallations, masterdataTree }),
    commonsAndInstallations,
    masterdataTreeRemoteData
  )

  return (
    <div className="card">
      <FormLayout.Content>
        <h3>{`Duplicating from ${duplicatingFrom}`}</h3>
        <InnerContainer>
          <Label>
            <span>Name</span>
            <input type="text" className="textfield" value={name} onChange={(e) => setName(e.target.value)} />
          </Label>
        </InnerContainer>
        <ColumnContainer>
          {SRD.match(
            {
              notAsked: () => <div>Empty</div>,
              loading: () => (
                <React.Fragment>
                  <h3>Installation Scope</h3>
                  <div data-testid="loading">
                    <div className="loadingSpinner" />
                  </div>
                </React.Fragment>
              ),
              failure: (err) => <div className="alert alert-danger">Error: {err.body?.title}</div>,
              success: ({ commonsAndInstallations, masterdataTree }) => (
                <React.Fragment>
                  <div>
                    <h3>Installation Scope</h3>
                    <button
                      className="btn btn-primary-dark py-sm"
                      // TODO: Use correct role (also used in tests)
                      role="edit-scope-button" // eslint-disable-line jsx-a11y/aria-role
                      onClick={() => setShowInstallationScopeConfiguration(true)}
                    >
                      Configure
                    </button>
                  </div>
                  <ScopeSummary scopes={modifiedScopes} data={masterdataTree.structureNodes} />
                  {showInstallationScopeConfiguration ? (
                    <Dialog
                      onClose={() => setShowInstallationScopeConfiguration(false)}
                      shouldCloseOnOverlayClick={false}
                    >
                      <div className="card">
                        <MasterdataAssignment
                          hierarchyData={masterdataTree.structureNodes}
                          claimedScopesMap={getClaimedScopesMap(
                            commonsAndInstallations,
                            masterdataTree.structureNodes,
                            selectedCountry,
                            modifiedScopes
                          )}
                          initialScopes={modifiedScopes}
                          onComplete={(scope) => {
                            setModifiedScopes(scope)
                            setShowInstallationScopeConfiguration(false)
                          }}
                        />
                      </div>
                    </Dialog>
                  ) : null}
                </React.Fragment>
              ),
            },
            combinedRemoteData
          )}
        </ColumnContainer>
        <InnerContainer>{errorMessage ? <p>{errorMessage}</p> : null}</InnerContainer>
        <FormLayout.FooterContainer>
          <FormLayout.Footer>
            <button
              className="btn btn-primary-dark py-sm"
              // TODO: Use correct role (also used in tests)
              role="submit-button" // eslint-disable-line jsx-a11y/aria-role
              disabled={!SRD.isSuccess(masterdataTreeRemoteData) || !name || isLoading}
              onClick={() => {
                setIsLoading(true)
                onComplete({
                  name,
                  installationScopes: toInstallationScope(modifiedScopes),
                })
                  .then(refresh)
                  .catch((err) => {
                    logError(err)
                    setErrorMessage(err.toString())
                    setIsLoading(false)
                  })
              }}
            >
              <FormLayout.LoadingButtonText loading={isLoading} text="Submit" loadingText="Submitting..." />
            </button>
          </FormLayout.Footer>
        </FormLayout.FooterContainer>
      </FormLayout.Content>
    </div>
  )
}
