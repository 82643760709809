import React from 'react'
import { NodeStatus, ScopeFilterWithStatus } from '../../lib/InstallationScope'
import { Checkbox } from './Checkbox'
import styled from 'styled-components'
import { toDisplayText } from '../../lib/StringUtilities'

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0.5em;
`

const Filter = styled.div`
  display: flex;
  padding: 5px 5px 5px 9px;
  > * {
    align-self: center;
  }
`

type Props = {
  filters: ScopeFilterWithStatus[]
  onChange: (filter: ScopeFilterWithStatus) => void
}

export const FilterSummary = ({ filters, onChange }: Props) => {
  return (
    <FilterContainer>
      {filters.map((filter) => {
        const filterId = toDisplayText(filter.type) + ': ' + filter.value
        return (
          <Filter key={filterId}>
            <Checkbox
              status={filter.status}
              disabled={false}
              onChange={() => {
                switch (filter.status) {
                  case NodeStatus.INCLUDED:
                    onChange({ ...filter, status: NodeStatus.EXCLUDED })
                    break
                  case NodeStatus.EXCLUDED:
                    onChange({ ...filter, status: NodeStatus.NONE })
                    break
                  case NodeStatus.NONE:
                    onChange({ ...filter, status: NodeStatus.INCLUDED })
                    break
                }
              }}
            />
            <span style={{ textTransform: 'capitalize' }}>{filterId}</span>
          </Filter>
        )
      })}
    </FilterContainer>
  )
}
