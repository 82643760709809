import React from 'react'
import { colors } from '../styles'

type Props = {
  open: boolean
  color?: string
  size?: string
}

export const MenuIcon = ({ open, color, size }: Props) =>
  open ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={size ?? '24'} height={size ?? '24'} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z"
        fill={color || colors.SECONDARY_TEXT}
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={size ?? '24'} height={size ?? '24'} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" fill={color || colors.SECONDARY_TEXT} />
    </svg>
  )
