import React, { useState } from 'react'
import { FormLayout } from 'styles/FormLayout'

type Props = {
  entityName: string
  onConfirm: () => Promise<void>
  onClose: () => Promise<void>
}

export const ConfirmDeletionDialog = ({ entityName, onConfirm, onClose }: Props) => {
  const [confirmText, setConfirmText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  return (
    <div className="card">
      <form
        className="p-xl"
        onSubmit={(e) => {
          e.preventDefault()
          setIsLoading(true)
          onConfirm()
            .then(() => setIsLoading(false))
            .catch((err) => {
              setErrorMessage(err.toString())
              setIsLoading(false)
            })
        }}
        onReset={() => {
          setIsLoading(true)
          onClose().then(() => setIsLoading(false))
        }}
      >
        <h1 className="font-bold my-md">WARNING: This will permanently remove {entityName}</h1>
        Confirm deletion of '{entityName}' by typing the name exact:
        <input
          type="text"
          className="textfield my-sm"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
        />
        {errorMessage ? <div className="alert alert-danger my-sm">{errorMessage}</div> : null}
        <div className="flex justify-between">
          <button type="reset" className="btn">
            Cancel
          </button>
          <button type="submit" className="btn btn-primary-danger" disabled={confirmText !== entityName || isLoading}>
            <FormLayout.LoadingButtonText loading={isLoading} text="Delete" loadingText="Deleting..." />
          </button>
        </div>
      </form>
    </div>
  )
}
