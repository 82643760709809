import React from 'react'
import { colors } from '../styles'

type AddCircleProps = {
  color?: string
  size?: string
}

export const AddCircleIcon = (props: AddCircleProps) => (
  <svg
    fill={props.color || colors.BLACK}
    height={props.size || '24'}
    viewBox="0 0 24 24"
    width={props.size || '24'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 7.5L12 16.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
    <path d="M7.5 12L16.5 12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
    <path
      d="M0.750 12.000 A11.250 11.250 0 1 0 23.250 12.000 A11.250 11.250 0 1 0 0.750 12.000 Z"
      fill="none"
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
)

export default AddCircleIcon
