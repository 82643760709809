import React, { ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { CloseIcon } from '../icons'

const ESC_KEY = 'Escape'

const noOp = () => {}

type Props = {
  onClose?: React.EventHandler<any>
  shouldCloseOnOverlayClick?: boolean
  overlayStyle?: Object
  overlayClassName?: string
  contentClassName?: string
  children: ReactNode
} & typeof defaultProps

const defaultProps = {
  shouldCloseOnOverlayClick: true,
}

export function Dialog(props: Props) {
  const { onClose, overlayStyle, children, shouldCloseOnOverlayClick } = props

  useEffect(() => {
    const _handleEscapeKey = (event: KeyboardEvent) => {
      if (onClose && event.key === ESC_KEY) {
        event.stopPropagation()
        onClose(event)
      }
    }

    window.addEventListener('keydown', _handleEscapeKey)
    return () => {
      window.removeEventListener('keydown', _handleEscapeKey)
    }
  }, [onClose])

  const mountNode = document.getElementById('root')

  if (!mountNode) return null

  const overlayClassName =
    'p-lg inset-0 fixed flex items-center justify-center bg-black-transparent z-50 ' + (props.overlayClassName ?? '')

  const contentClassName = 'relative max-h-window' + (props.contentClassName ?? '')

  return ReactDOM.createPortal(
    <div onClick={shouldCloseOnOverlayClick ? onClose : noOp} className={overlayClassName} style={overlayStyle}>
      <div className={contentClassName} onClick={(evt) => evt.stopPropagation()}>
        {children}
        {onClose && (
          <button className={'btn btn-icon absolute top-0 right-0'} onClick={onClose}>
            <CloseIcon />
          </button>
        )}
      </div>
    </div>,
    mountNode
  )
}
Dialog.defaultProps = defaultProps
