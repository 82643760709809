import React, { useState } from 'react'
import { FormLayout } from 'styles/FormLayout'
import { InnerContainer, Label } from 'styles/PageLayout'

const types = ['SHAPE', 'BARCODE', 'CRATE']

type Edit = {
  name: string
  type: string
}

type Props = {
  editing?: Edit
  onComplete: (name: string, type: string) => Promise<void>
}

export const FileStreamDialog = ({ onComplete, editing }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [name, setName] = useState(editing ? editing.name : '')
  const [type, setType] = useState(editing ? editing.type : 'SHAPE')
  const operationText = editing ? 'Edit' : 'Create'
  return (
    <div className="card">
      <div className="p-xl">
        <h3>{`${operationText} File Stream`}</h3>
        <InnerContainer>
          <Label>
            <span>Name</span>
            <input type="text" className="textfield" value={name} onChange={(e) => setName(e.target.value)} />
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          </Label>
        </InnerContainer>
        <InnerContainer>
          <Label>
            <span>File Type</span>
            {editing ? (
              <p>{type}</p>
            ) : (
              <select className="select" onChange={(e) => setType(e.target.value)}>
                {types.map((availableType) => {
                  return (
                    <option key={availableType} value={availableType}>
                      {availableType}
                    </option>
                  )
                })}
              </select>
            )}
          </Label>
        </InnerContainer>
        <InnerContainer>{errorMessage ? <p>{errorMessage}</p> : null}</InnerContainer>
        <FormLayout.FooterContainer>
          <FormLayout.Footer>
            <button
              className="btn btn-primary-dark py-sm"
              disabled={isLoading}
              onClick={() => {
                setIsLoading(true)
                onComplete(name, type).catch((err) => {
                  setErrorMessage(err)
                  setIsLoading(false)
                })
              }}
            >
              <FormLayout.LoadingButtonText loading={isLoading} text="Submit" loadingText="Submitting..." />
            </button>
          </FormLayout.Footer>
        </FormLayout.FooterContainer>
      </div>
    </div>
  )
}
