import React from 'react'

type SearchIconProps = {
  color?: string
  size?: string
}

export const SearchIcon = (props: SearchIconProps) => (
  <svg
    fill={props.color || 'none'}
    height={props.size || '24'}
    width={props.size || '24'}
    viewBox="0 0 24 24"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)" />
    <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25" />
  </svg>
)

export default SearchIcon
