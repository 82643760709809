import React from 'react'

type Props = {
  numberOfStaged: number
  onClear: () => void
  onApply: () => Promise<void>
}

export const StagedModifications = ({ numberOfStaged, onClear, onApply }: Props) => {
  return (
    <div className="flex items-center">
      <span className="font-bold">Number of affected rows: {numberOfStaged}</span>
      <button className="btn btn-primary-dark mx-md" disabled={numberOfStaged === 0} onClick={() => onApply()}>
        Apply staged changes
      </button>
      <button className="btn" onClick={() => onClear()} disabled={numberOfStaged === 0}>
        Clear
      </button>
    </div>
  )
}
