// @ts-nocheck
const { ENV, STAGE, CONFIG_BY_STAGE, LOCALE, TIME_ZONE, VERSION } = window.__ENVIRONMENT__
const { CONFIG_BY_ENV } = CONFIG_BY_STAGE[STAGE]
const { API_HOST, KEYCLOAK_HOST } = CONFIG_BY_ENV[ENV]
const ORIG_ENV_QUERY_NAME = 'origEnv'
const ENV_NAME = `${ENV} - ${STAGE}`

export { ENV, ENV_NAME, API_HOST, KEYCLOAK_HOST, VERSION, STAGE, ORIG_ENV_QUERY_NAME, LOCALE, TIME_ZONE }

function getEnvToTcUrlMap() {
  const mappings = CONFIG_BY_ENV[ENV].ENV_TO_TC_URL_MAP.split(';')
  let map: { [key: string]: string } = {}
  mappings.forEach((element) => {
    const [env, url] = element.split('=')
    map[env] = url
  })
  return map
}

export const EnvToUrlMap = getEnvToTcUrlMap()
