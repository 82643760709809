const colors = {
  LIGHT_PRIMARY: '#3DBAD5',
  PRIMARY: 'rgb(0, 139, 199)',
  DARK_PRIMARY: 'rgb(0, 71, 132)',
  PRIMARY_TEXT: '#2D3748',
  SECONDARY_TEXT: '#757575',
  TERTIARY_TEXT: '#CCCCCC',
  GREY: '#F1F1F1',
  LIGHT_GREY: '#d2d2d2',
  LIGHTER_GREY: '#e3e3e3',
  LIGHT_GREEN: '#B0EAC8',
  GREEN: 'rgb(46, 204, 113)',
  DARK_GREEN: 'rgb(6, 164, 73)',
  LIGHT_RED: '#F4B8B3',
  RED: 'rgb(231, 76, 60)',
  DARK_RED: 'rgb(191, 36, 20)',
  LIGHT_YELLOW: '#fbffcd',
  YELLOW: '#ffcd00',
  DARK_YELLOW: 'rgb(201, 156, 0)',
  LIGHT_BLUE: 'rgb(75, 191, 219)',
  BLUE: 'rgb(47, 124, 255)',
  NAVBAR: 'rgb(0, 26, 56)',
  WHITE: '#FFF',
  BLACK: '#000',
}

export default colors
