import React, { ReactNode, useState } from 'react'
import { MenuIcon } from '../../icons'
import styled from 'styled-components'

type Props = {
  children: ReactNode
}

export const DiffHoverTooltip = ({ children }: Props) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      style={{
        width: '30px',
        height: '30px',
        border: '1px solid rgba(117, 117, 117, 0.2)',
        borderRadius: '14px',
        padding: 2,
        verticalAlign: 'middle',
        display: 'inline-block',
      }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      data-testid="tooltip"
    >
      <MenuIcon open={open} />
      {open ? (
        <div style={{ display: 'relative' }}>
          <HoverContent>
            <div className="card" style={{ boxShadow: '0px 0px 15px grey' }}>
              {children}
            </div>
          </HoverContent>
        </div>
      ) : null}
    </div>
  )
}

const HoverContent = styled.div`
  position: absolute;
  margin-top: -5px;
  margin-left: -25em;
  overflow: none;
  display: flex;
  flex-flow: column nowrap;
  align-self: flex-end;
  justify-content: flex-end;
  z-index: 999;
`
